<template>
  <div
    class="t-preloader"
    :class="{ [`t-preloader--${color}`]: color !== '' }"
    :style="{ width: `${size}px`, height: `${size}px` }"
  />
</template>

<script>
export default {
  name: 'TPreloader',

  props: {
    color: {
      type: String,
      default: '',
    },

    size: {
      type: [String, Number],
      default: 50,
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

$colors: (
  'white': #fff,
);

.t-preloader {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background-color: $steel-grey;
  animation: preloader 0.8s infinite ease-in-out;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @each $color-name, $color-value in $colors {
    &--#{$color-name} {
      background-color: $color-value;
    }
  }
}

@keyframes preloader {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
