export const dict = {
  custom: {
    phone: {
      regex: () => 'Введите действительный номер телефона',
    },
    responsible_person_phone: {
      regex: () => 'Введите действительный номер телефона',
    },
    number: {
      regex: () => 'Введите действительный номер',
    },
    password: {
      min: () => 'Пароль неверный',
    },
    sts: {
      required: () => 'Добавьте файл',
    },
    docs: {
      required: () => 'Добавьте файл',
    },
    agreement_documents: {
      required: () => 'Загрузите файл',
    },
    instant_price: {
      min_value: (field, [min, value]) => `Ставка должна быть больше ${min - 1}${value || ''}`,
    },
    generalLimit: {
      max_value: (field, [max, value]) => (!max
        ? 'Укажите количество груза'
        : `Поле должно быть ${max}${value || ''} или менее`),
    },
  },
};

export default {
  after: (field, [target]) => `В поле должна быть дата после ${target}`,
  alpha_dash: () => 'Поле может содержать только буквы, цифры и дефис',
  alpha_num: () => 'Поле может содержать только буквы и цифры',
  alpha_spaces: () => 'Поле может содержать только буквы и пробелы',
  alpha: () => 'Поле может содержать только буквы',
  before: (field, [target]) => `В поле должна быть дата до ${target}`,
  between: (field, [min, max]) => `Поле должно быть между ${min} и ${max}`,
  confirmed: (field, [confirmedField]) => `Поле не совпадает с ${confirmedField}`,
  date_between: (field, [min, max]) => `Поле должно быть между ${min} и ${max}`,
  date_format: (field, [format]) => `Поле должно быть в формате ${format}`,
  decimal: () => 'Поле может быть числовым и с плавающей точкой',
  digits: () => 'Поле должно быть числовым',
  dimensions: (field, [width, height]) => `Поле должно быть ${width} пикселей на ${height} пикселей`,
  email: () => 'Введите действительный email',
  phone: () => 'Введите действительный номер телефона',
  ext: () => 'Поле должно быть действительным файлом',
  image: () => 'Поле должно быть изображением',
  in: () => 'Поле должно быть допустимым значением',
  ip: () => 'Поле должно быть действительным IP-адресом',
  max: (field, [length]) => `Поле должно содержать не более ${length} символов`,
  max_value: (field, [max, value]) => `Поле должно быть ${max}${value || ''} или менее`,
  mimes: () => 'Поле должно иметь действительный тип файла',
  min: (field, [length]) => `Поле должно содержать не менее ${length} символов`,
  min_value: (field, [min, value]) => `Поле должно быть ${min}${value || ''} или больше`,
  not_in: () => 'Поле должно быть допустимым значением',
  numeric: () => 'Поле должно быть числом',
  regex: () => 'Неверный формат',
  required: () => 'Заполните поле',
  size: (field, [size]) => `Поле должно быть меньше, чем ${size} KB`,
  url: () => 'Введите правильный URL',
  length: (field, [size]) => `Поле должно содержать ${size} символов`,
  inn: (field, [innLength1, innLength2]) => `Введите ИНН из ${innLength1} или ${innLength2} цифр`,
  items: () => 'Выберите элемент из списка',
  time_period: () => 'Время окончания должно быть позже времени начала',
  max_integer_value: (field, [max, value]) => `Поле должно быть ${max}${value || ''} или менее`,
  min_integer_value: (field, [min, value]) => `Поле должно быть ${min}${value || ''} или более`,
  loading_restriction: (field, [max]) => (Number(max) === 0
    ? 'Превышено значение максимальной суточной погрузки'
    : `Количество груза должен быть ${max} или меньше`),
};
