export const state = () => ({});

export const actions = {
  async nuxtServerInit({ commit, dispatch }, { app }) {
    const token = app.$cookies.get('token');

    const nds = app.$cookies.get('nds');

    if (app.$cookies.get('nds') === undefined) {
      app.$cookies.set('nds', true);
    }

    const isMenuOpened = app.$cookies.get('isMenuOpened');

    if (isMenuOpened !== undefined) {
      commit('general/setMenuShrunk', isMenuOpened);
    }

    commit('general/setNdsValue', nds);

    if (token) {
      commit('general/setToken', token);
      await dispatch('general/SET_AUTH_HEADER', token);
      await dispatch('general/SET_AUTH_COOKIE', token);
    }
  },
};
