import { CancelToken, isCancel } from 'axios';
import { formatPeriods } from '~/assets/js/utils';

export const state = () => ({
  timeslotList: [],
  cargoStations: [],
  countTurns: null,
  loadingPeriods: null,
  products: [],
  statuses: [],
  unloadingPeriods: [],
  dqMode: null,
  cargoStationType: null,
  groupList: [],
  orderList: [],
  history: [],
  toastMessage: '',
  timeslotQuery: {},
  lastLoadedParams: null,
  isTableLoading: false,
  timeslotsDirection: null,
});

export const getters = {
  getTimeslotList: state => state.timeslotList,
  getCargoStations: state => state.cargoStations,
  getCountTurns: state => state.countTurns,
  getLoadingPeriods: state => state.loadingPeriods,
  getUnloadingPeriods: state => state.unloadingPeriods,
  getProducts: state => state.products,
  getStatuses: state => state.statuses,
  getDqMode: state => state.dqMode,
  getCargoStationType: state => state.cargoStationType,
  getSelectedClient: state => state.selectedClient,
  geSelectedTransporter: state => state.selectedTransporter,
  getGroupList: state => state.groupList,
  getOrderList: state => state.orderList,
  getHistory: state => state.history,
  getToastMessage: state => state.toastMessage,
  getTimeslotQuery: state => state.timeslotQuery,
  getLastLoadedParams: state => state.lastLoadedParams,
  isTableLoading: state => state.isTableLoading,
  getTimeslotsDirection: (state, _getters, _rootState, rootGetters) => {
    return state.timeslotsDirection || rootGetters['main/getDqMode'];
  },
};

export const mutations = {
  setTimeslotsData(state, payload) {
    const formattedList = Object.keys(payload.groups).map(key => ({
      name: key,
      ...payload.groups[key],
    }));

    const totalRow = {
      count_trips: payload.tech_date_count_trips,
      total_weight: payload.tech_date_total_weight,
      name: this.$i18n.t('trips.Total per day'),
      isTotalRow: true,
      statuses_statistic: payload.total_statuses_statistic,
    };
    state.timeslotList = [totalRow, ...formattedList];
  },

  setCargoStations(state, payload) {
    state.cargoStations = payload;
  },

  setCountTurns(state, payload) {
    state.countTurns = payload;
  },

  setLoadingPeriods(state, payload) {
    state.loadingPeriods = payload.length
      ? payload
      : Object.values(payload);
  },

  setUnloadingPeriods(state, payload) {
    state.unloadingPeriods = payload.length
      ? payload
      : Object.values(payload);
  },

  setProducts(state, payload) {
    state.products = payload;
  },

  setStatuses(state, payload) {
    state.statuses = payload;
  },

  setDqMode(state, payload) {
    state.dqMode = payload;
  },

  setCargoStationType(state, payload) {
    state.cargoStationType = payload;
  },

  setSelectedClient(state, payload) {
    state.selectedClient = payload;
  },

  seSelectedTransporter(state, payload) {
    state.selectedTransporter = payload;
  },

  setGroupList(state, payload) {
    state.groupList = Object.keys(payload).map(key => ({
      id: key,
      name: payload[key],
    }));
  },

  setOrderList(state, payload) {
    state.orderList = Object.keys(payload).map(key => ({
      id: key,
      name: payload[key],
    }));
  },

  setHistory(state, payload) {
    state.history = Object.values(payload);
  },

  setToastMessage(state, payload) {
    state.toastMessage = payload;
  },

  refreshToastMessage(state) {
    state.toastMessage = '';
  },

  setTimeslotQuery(state, payload) {
    state.timeslotQuery = payload;
  },

  setLastLoadingParams(state, payload) {
    state.lastLoadedParams = payload;
  },

  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },

  setTimeslotsDirection(state, payload) {
    state.timeslotsDirection = payload;
  },
};

export const actions = {
  async loadTimeslotList({ commit }, filters) {
    if (this.cancelSource) {
      this.cancelSource.cancel(
        'Request cancelled'
      );
    }
    this.cancelSource = CancelToken.source();

    commit('setTableLoading', true);

    await this.$axios.get('trips', {
      params: {
        ...filters,
        loading_periods: formatPeriods(filters.loading_periods),
        unloading_periods: formatPeriods(filters.unloading_periods),
      },
      cancelToken: this.cancelSource && this.cancelSource.token,
    })
      .then(({ data }) => {
        commit('setLastLoadingParams', filters);
        commit('setCargoStations', data.cargoStations);
        commit('setCountTurns', data.countTurns);
        commit('setLoadingPeriods', data.loadingPeriods);
        commit('setUnloadingPeriods', data.unloadingPeriods);
        commit('setProducts', data.products);
        commit('setStatuses', data.statuses);
        commit('setDqMode', data.dqMode);
        commit('setSelectedClient', data.selectedClient);
        commit('seSelectedTransporter', data.selectedTransporter);
        commit('setCargoStationType', data.cargoStationType);
        commit('setTimeslotsData', data.data);
        commit('setGroupList', data.groupByLabels);
        commit('setOrderList', data.orderByLabels);
      })
      .catch(error => {
        if (isCancel(error)) {
          console.log('Request canceled');
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async loadTimeslotListWithoutPreloader({ commit }, filters) {
    await this.$axios.get('trips', {
      params: {
        ...filters,
        loading_periods: formatPeriods(filters.loading_periods),
        unloading_periods: formatPeriods(filters.unloading_periods),
      },
    })
      .then(({ data }) => {
        commit('setLastLoadingParams', filters);
        commit('setCargoStations', data.cargoStations);
        commit('setCountTurns', data.countTurns);
        commit('setLoadingPeriods', data.loadingPeriods);
        commit('setUnloadingPeriods', data.unloadingPeriods);
        commit('setProducts', data.products);
        commit('setStatuses', data.statuses);
        commit('setDqMode', data.dqMode);
        commit('setSelectedClient', data.selectedClient);
        commit('seSelectedTransporter', data.selectedTransporter);
        commit('setCargoStationType', data.cargoStationType);
        commit('setTimeslotsData', data.data);
        commit('setGroupList', data.groupByLabels);
        commit('setOrderList', data.orderByLabels);
      })
      .catch(error => console.log(error));
  },

  loadTimeslotHistory({ commit }, id) {
    return this.$axios.get(`/trips/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);
        return data;
      }).catch(err => {
        console.log(err);
      });
  },
};
