import https from 'https';

class Dadata {
  constructor(apiKey, axios) {
    this.apiKey = apiKey;
    this.axios = axios;
  }

  client(api, params) {
    const request = {
      count: 10,
      ...params,
    };

    const suggestionsTypes = [
      'address',
      'fio',
      'party',
      'bank',
      'email',
      'fias',
      'fms_unit',
      'postal_unit',
      'fns_unit',
      'region_court',
      'metro',
      'car_brand',
      'country',
      'currency',
      'okved2',
      'okpd2',
    ];

    if (!suggestionsTypes.includes(api)) {
      return Promise.reject(new Error('wrong type'));
    }

    const options = {
      method: 'POST',
      hostname: 'suggestions.dadata.ru',
      path: `/suggestions/api/4_1/rs/suggest/${api}`,
      port: 443,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${this.apiKey}`,
      },
    };

    return new Promise((resolve, reject) => {
      const req = https.request(options, res => {
        let result = '';

        if (res.statusCode !== 200) {
          reject(new Error('HTTP Error: bad status code'));
        }

        res.on('data', chunk => {
          result += chunk;
        });

        res.on('end', () => {
          try {
            result = JSON.parse(result);
          } catch (err) {
            reject(err);
          }

          resolve(result);
        });
      });
      req.on('error', reject);
      req.write(JSON.stringify(request));
      req.end();
    });
  }

  async search(query, type = 'address', options = {}) {
    try {
      return await this.client(type, { query, ...options });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

const dadata = new Dadata(process.env.DADATA_TOKEN);

export default async function searchDadata(query, type = 'address', options = {}) {
  try {
    return await dadata.search(query, type, options);
  } catch (error) {
    return Promise.reject(error);
  }
}
