export const state = () => ({
  transportList: [],
  isTransportListLoading: false,
  total: 0,
  currentPage: 0,
  perPage: 0,
  query: {},
});

export const getters = {
  GET_TRANSPORT_LIST: state => state.transportList,
  GET_QUERY: state => state.query,
  GET_TRANSPORT_LIST_LOADING: state => state.isTransportListLoading,
  GET_PAGINATION: state => ({
    total: state.total,
    currentPage: state.currentPage,
    perPage: state.perPage,
  }),
};

export const actions = {
  LOAD_TRANSPORT_LIST({ commit, dispatch, state }, payload) {
    commit('setTransportListLoading', true);

    let filters = {};
    if (Number.isInteger(Number(payload))) {
      filters = {
        ...state.query,
        page: payload,
      };
    } else {
      filters = {
        ...state.query,
        ...payload,
      };
    }

    return this.$axios.get('/cars/index', { params: { ...filters } })
      .then(({ data }) => {
        commit('setTransportList', data.cars);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        commit('setTransportListLoading', false);
      });
  },
  async DELETE_CAR({ commit, dispatch }, payload) {
    await this.$axios.post('/cars/delete', {
      id: payload,
    }).then(() => {
      dispatch('LOAD_TRANSPORT_LIST');
    });
  },
  SAVE_QUERY({ commit, dispatch }, payload) {
    commit('saveQuery', payload);
  },
};

export const mutations = {
  setTransportListLoading(state, payload) {
    state.isTransportListLoading = payload;
  },
  saveQuery(state, payload) {
    state.query = payload;
  },
  setTransportList(state, payload) {
    state.transportList = payload.data;
    state.total = payload.total;
    state.currentPage = payload.current_page;
    state.perPage = payload.per_page;
  },
};
