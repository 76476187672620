import Vue from 'vue'
import { Bar } from 'vue-chartjs/legacy'
import { Chart, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

Vue.component('BarChart', {
  extends: Bar,
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => {},
    },
    options: {
      type: Object,
      required: false,
      // default: () => ({
      //   skipNull: true,
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   scales: {
      //     x: {
      //       stacked: true,
      //     },
      //     y: {
      //       stacked: true,
      //       beginAtZero: true,
      //     }
      //   },
      //   plugins: {
      //     legend: {
      //       display: false,
      //     },
      //   },
			// 	onClick: (e) => {
			// 		console.log(e.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true));
      //     // $router.push('transporters-report')
			// 	}
      // }),
    },
  },
  watch: {
    data() {
      this.renderChart(this.chartData, this.options)
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
})
