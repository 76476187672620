export const dict = {
  custom: {
    phone: {
      regex: () => 'Please enter a valid phone number',
    },
    responsible_person_phone: {
      regex: () => 'Please enter a valid phone number',
    },
    number: {
      regex: () => 'Please enter a valid number',
    },
    password: {
      min: () => 'Wrong password',
    },
    sts: {
      required: () => 'Add file',
    },
    docs: {
      required: () => 'Add file',
    },
    agreement_documents: {
      required: () => 'Upload file',
    },
    instant_price: {
      min_value: (field, [min, value]) => `The rate must be higher than ${min - 1}${value || ''}`,
    },
    generalLimit: {
      max_value: (field, [max, value]) => (!max
        ? 'Indicate the amount of cargo'
        : `The field must be ${max} ${value || ''} or less`),
    },
  },
};

export default {
  after: (field, [target]) => `ველში უნდა იყოს თარიღი ${target} – ის შემდეგ`,
  alpha_dash: () => 'ველი შეიძლება შეიცავდეს მხოლოდ ასოებს, ციფრებს და დეფისს',
  alpha_num: () => 'ველი შეიძლება შეიცავდეს მხოლოდ ასოებს და ციფრებს',
  alpha_spaces: () => 'ველი შეიძლება შეიცავდეს მხოლოდ ასოებს და მანძილებს',
  alpha: () => 'ველი შეიძლება შეიცავდეს მხოლოდ ასოებს',
  before: (field, [target]) => `ველში უნდა იყოს თარიღი ${target} – ის წინ`,
  between: (field, [min, max]) => `ველი უნდა იყოს ${min} და ${max} შორის`,
  confirmed: (field, [confirmedField]) => `ველი არ შეესაბამება ${confirmedField} – ს`,
  date_between: (field, [min, max]) => `ველი უნდა იყოს ${min} და ${max} შორის`,
  date_format: (field, [format]) => `ველი უნდა იყოს ${format} ფორმატში`,
  decimal: () => 'ველი შეიძლება იყოს ციფრული და ცვლადი წერტილით',
  digits: () => 'ველი უნდა იყოს ციფრული',
  dimensions: (field, [width, height]) => `მინდორი უნდა იყოს ${width} პიქსელი და ${height} პიქსელი`,
  email: () => 'თუ შეიძლება, შეიყვანეთ მოქმედი ელ–ფოსტის მისამართი',
  phone: () => 'Please enter a valid phone number',
  ext: () => 'ველი უნდა იყოს ვალიდური ფაილის სახით',
  image: () => 'ველი უნდა იყოს გამოსახულების სახით',
  in: () => 'ველი უნდა იყოს დასაშვები მნიშვნელობის',
  ip: () => 'ველი უნდა იყოს მოქმედი IP მისამართი',
  max: (field, [length]) => `ველი უნდა შეიცავდეს არა უმეტეს ${length} სიმბოლოს`,
  max_value: (field, [max, value]) => `ველი უნდა იყოს ${max}${value || ''} ან ნაკლები`,
  mimes: () => 'ველი უნდა წარმოადგენდეს ვალიდური ფაილის ტიპს',
  min: (field, [length]) => `ველი უნდა შეიცავდეს არანაკლებ ${length} სიმბოლოს`,
  min_value: (field, [min, value]) => `ველი უნდა იყოს ${min}${value || ''} ან მეტს`,
  not_in: () => 'ველი უნდა იყოს დასაშვები მნიშვნელობის',
  numeric: () => 'ველი უნდა იყოს ციფრის სახით',
  regex: () => 'არასწორი ფორმატი',
  required: () => 'შეავსეთ ველი',
  size: (field, [size]) => `ველი უნდა იყოს ${size} KB–ზე ნაკლები`,
  url: () => 'შეიყვანეთ სწორი URL',
  length: (field, [size]) => `ველი უნდა შეიცავდეს ${size} სიმბოლოს`,
  inn: (field, [innLength1, innLength2]) => `შეიყვანეთ INN, რომელიც შედგება ${innLength1} ან ${innLength2} ციფრიდან`,
  items: () => 'აირჩიეთ ელემენტი სიიდან',
  time_period: () => 'დასრულების დრო უნდა იყოს დაწყების დროზე უფრო გვიანდელი',
  max_integer_value: (field, [max, value]) => `ველი უნდა იყოს ${max} ${value || ''} ან ნაკლები`,
  min_integer_value: (field, [min, value]) => `ველი უნდა იყოს ${min} ${value || ''} ან მეტი`,
  loading_restriction: (field, [max]) => (Number(max) === 0
    ? 'მაქციმალური დღიური დატვირთვის მნიშვნელობა გადაჭარბებულია'
    : `ტვირთის რაოდენობა უნდა იყყოს ${max} ან ნაკლები`),
};
