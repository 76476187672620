import { v4 as uuid } from 'uuid';
import { transliterate } from 'transliteration';
import { isEmpty, isArray, clone } from 'chober';

const declensionsModel = {
  section: ['раздел', 'раздела', 'разделов'],
  order: ['заказ', 'заказа', 'заказов'],
  client: ['заказчик', 'заказчика', 'заказчиков'],
  transporter: ['перевозчик', 'перевозчика', 'перевозчиков'],
};

export const regex = {
  phoneRegex: /^(\+(?!8)\d{1,3})[ ]+(\d|-| ){13,17}/,
  numRegex: /^([0-9]{0,10}?)$/,
  numbersAndLetters: /[A-zА-яЁё0-9]/i,
  passportSerialRu: /^\d{4} \d{6}$/,
  driverLicenseRu: /^\d{2} [0-9А-ЯЁ]{2} \d{6}$/i,
};

export const toDashCase = string => string.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);

export const unmaskValue = (value, regularExp = regex.numbersAndLetters) => {
  if (!value) return null;
  const valueArray = String(value).split('');
  return valueArray.map(cur => {
    if (regularExp.test(cur)) {
      return cur;
    }
    return '';
  }).join('');
};

export const formatCarTrailerNumber = value => {
  const valueArr = value.split('');

  valueArr.splice(6, 0, ' ');

  return valueArr.join('');
};

export const formatDocumentsNumber = (value, document) => {
  let valueArr = String(value).split('');

  if (document === 'passport') {
    valueArr.splice(4, 0, ' ');
  } else if (document === 'license') {
    const firstPart = valueArr.slice(0, 2);
    const secondPart = valueArr.slice(2, 4);
    const thirdPart = valueArr.slice(4);

    valueArr = [...firstPart, ' ', ...secondPart, ' ', ...thirdPart];
  }

  return valueArr.join('');
};

export const objectify = (object, [key, value]) => ({
  ...object,
  [key]: value,
});

export const exscindFileName = file => file.split('/').pop();

export const getIdOnly = value => value.id;

export const getMultipickerValueFromId = (id, options) => options.find(cur => Number(cur.id) === Number(id));

export const decline = (declensionName, number) => {
  const cases = [2, 0, 1, 1, 1, 2];
  const declensions = declensionsModel[declensionName];

  return declensions[(number % 100 > 4 && number % 100 < 20)
    ? 2
    : cases[(number % 10 < 5) ? number % 10 : 5]];
};

export const parseFileLinks = fileLinks => {
  const fileLinksReady = Array.isArray(fileLinks) ? fileLinks : JSON.parse(fileLinks);

  return fileLinksReady.map(fileLink => ({
    name: exscindFileName(fileLink),
    link: fileLink,
    id: uuid(),
  }));
};

export const parsePreviewFileLinks = fileLinks => fileLinks.map(fileLink => ({
  name: fileLink.file.name,
  created_by: fileLink.file.custom_properties.creator_short_name,
  created_at: fileLink.file.created_at,
  link: {
    url: fileLink.url,
    preview: fileLink.preview,
    fullUrl: fileLink.fullUrl,
  },
  id: uuid(),
  file: fileLink.file,
  comments: fileLink.comments || [],
}));

export const formatPhone = phone => {
  if (!phone) {
    return '';
  }

  if (phone.length >= 11 && phone.length <= 13) {
    return phone.replace(/(.+)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
  }

  if (phone.length > 13 && phone.length <= 15) {
    return phone.replace(/(.+)(\d{3})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4-$5-$6');
  }

  return phone;
};

// DQBOX-472 скрыть тикер
// export const setTicker = (shortName, letters, tickerTry) => {
//   const abbreviations = ['ООО', 'ИП', 'ОДО', 'ОАО', 'ЗАО', 'АО'];

//   const shortNameString = shortName.split(' ');

//   // check for abbreviations, remove if any
//   const abbrIndex = abbreviations.map(cur => shortNameString.findIndex(el => el === cur)).find(cur => cur !== -1);
//   if (abbrIndex >= 0) shortNameString.splice(0, 1);

//   // check for non-letters, remove if any
//   const words = shortNameString.map(cur => cur.split('').filter(el => letters.test(el)).join(''));

//   // check for capitalized single world, get capitals ex:"ТрансСтройМеханизация"
//   const capitalsInSingleWord = words.length === 1
//     ? words[0].split('').filter(cur => cur !== 'ь'
//       && cur !== 'ъ'
//       && cur !== 'ы'
//       && cur === cur.toUpperCase())
//     : [];

//   // CAPITALIZED SINGLE WORD HANDLING
//   // get index of capitals
//   const capitalsInSingleWorldIndex = [];
//   capitalsInSingleWord.forEach(cur => {
//     capitalsInSingleWorldIndex.push(words[0].split('').findIndex(el => el === cur));
//     return capitalsInSingleWorldIndex;
//   });

//   // get array of words splitted by capitals
//   const singleWordCapitalizedArray = words[0].split('');
//   const sigleWorldSplittedArray = [];
//   let number = 0;
//   capitalsInSingleWorldIndex.forEach((cur, index) => {
//     if (cur > 0 && index === 1) {
//       sigleWorldSplittedArray.push(singleWordCapitalizedArray.splice(0, cur));
//       number = cur;
//     } else if (cur > 0 && index > 1) {
//       sigleWorldSplittedArray.push(singleWordCapitalizedArray.splice(0, cur - number));
//       number = cur;
//     }
//   });
//   sigleWorldSplittedArray.push(singleWordCapitalizedArray);

//   // get array of letters for ticker
//   const wordsArray = !(words.length === 1 && isEmpty(capitalsInSingleWord))
//     ? words.map(cur => cur.split(''))
//     : sigleWorldSplittedArray;

//   let finalStringArray = [];
//   switch (wordsArray.length) {
//     case 1: {
//       finalStringArray = wordsArray[0].splice(0 + tickerTry, 4).join('');
//       break;
//     }

//     case 2: {
//       wordsArray.forEach(cur => {
//         finalStringArray.push(cur.splice(0 + tickerTry, 2).join(''));
//       });
//       break;
//     }

//     case 3: {
//       wordsArray.forEach((cur, index) => {
//         if (index === 0) finalStringArray.push(cur.splice(0 + tickerTry, 1).join(''));
//         finalStringArray.push(cur.splice(0 + tickerTry, 1).join(''));
//       });
//       break;
//     }

//     default: {
//       wordsArray.forEach(cur => {
//         finalStringArray.push(cur.splice(0 + tickerTry, 1).join(''));
//       });
//     }
//   }

//   // transliterate string
//   const stringTransliterated = transliterate(isArray(finalStringArray)
//     ? finalStringArray.join('')
//     : finalStringArray);

//   // check if string is longer than 4 chars, delete
//   const ticker = stringTransliterated.length > 4
//     ? stringTransliterated.split('').slice(0, 4).join('')
//     : stringTransliterated;

//   return ticker.toUpperCase();
// };

// DQBOX-472 скрыть тикер
// export const formatTicker = ticker => (ticker ? ticker.slice(0, 4) : '');

export const removeEmpty = obj => {
  const localObj = clone(obj);

  Object.keys(localObj).forEach(key => {
    if (((!localObj[key] && localObj[key] !== 0) || localObj[key] === -1 || localObj[key] === '')
      || localObj[key].length === 0) {
      delete localObj[key];
    }
  });

  return localObj;
};

export const normalizeNumber = number => Number(
  String(number || 0).split(' ').join('')
);

export function formatInteger(number, symbol = ' ') {
  const integer = Math.round(normalizeNumber(number));

  if (!number) return '0';

  return String(integer).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${symbol}`);
}

export const removeSeconds = time => {
  if (time) {
    return time === '23:59:59' ? '24:00' : time.slice(0, -3);
  }
  return '';
};

export function getCommaInArrayList(length, index) {
  return length - 1 > index ? ', ' : '';
}

// Get name of the city/town/etc. from full name of the place
export const getShortPlaceName = placeName => {
  const output = transformLocalityType(placeName);
  const placeArr = output.split(',');

  if (placeArr.length) {
    return placeArr[placeArr.length - 1];
  }

  return placeArr[0];
};

const transformLocalityType = placeName => {
  const localities = [
    {
      name: 'г ',
      shortName: 'г. ',
    },
    {
      name: 'город ',
      shortName: 'г. ',
    },
    {
      name: 'село ',
      shortName: 'с. ',
    },
    {
      name: 'поселок ',
      shortName: 'п. ',
    },
    {
      name: 'хутор ',
      shortName: 'х. ',
    },
    {
      name: 'деревня ',
      shortName: 'д. ',
    },
    {
      name: 'станица ',
      shortName: 'cт. ',
    },
  ];

  let output = placeName;

  localities.forEach(locality => {
    output = output.replace(locality.name, locality.shortName);
  });

  return output;
};

export const groupBy = (arr, param, withoutKeys = false) => {
  const groupedObject = arr.reduce((obj, item) => {
    const value = item[param];

    return {
      ...obj,
      ...(!isArray(value)
        ? {
          [value]: [
            ...(obj[value] || []),
            item,
          ],
        }
        : (
          value.reduce((obj2, param2) => ({
            ...obj2,
            [param2]: [
              ...(obj2[param2] || []),
              item,
            ],
          }), obj)
        )
      ),
    };
  }, {});

  return withoutKeys ? Object.values(groupedObject) : groupedObject;
};

export const getOnlyDigits = string => string && string.replace(/\D/g, '');

export const getTypes = (typesArr, arrKey) => typesArr.reduce(
  (acc, cur) => [
    ...acc,
    cur,
    ...(cur[arrKey] ? getTypes(cur[arrKey], arrKey) : []),
  ],
  []
);

export const formatPeriods = periods => {
  if (!periods) {
    return null;
  }

  const getFrom = periodStr => periodStr.match(/^\d\d:\d\d/);
  const getTo = periodStr => periodStr.match(/\d\d:\d\d$/);

  return periods.map(period => ({
    from: getFrom(period),
    to: getTo(period),
  }));
};

export const transformQueryArrays = (query, arrays) => {
  const transformedQuery = clone(query);

  arrays.forEach(key => {
    const value = transformedQuery[key];

    if (key in transformedQuery && (!value || !Array.isArray(value))) {
      transformedQuery[key] = value ? [value] : [];
    }
  });

  return transformedQuery;
};

export const getFromAndToQuotaTimes = (mode, timeSlotData) => {
  return {
    timeFrom: mode
      ? `${timeSlotData.loading_date} ${timeSlotData.loading_time_from}`
      : `${timeSlotData.unloading_date} ${timeSlotData.unloading_time_from}`,
    timeTo: mode
      ? `${timeSlotData.loading_date} ${timeSlotData.loading_time_to}`
      : `${timeSlotData.unloading_date} ${timeSlotData.unloading_time_to}`,
  };
};
