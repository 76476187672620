export const state = () => ({
  companiesData: {},
  filtersLabels: {},
  query: {},
  customerDocModelName: '',
  companyModelName: '',
  customerDocCollectionName: '',
  tempMediaModelName: '',
  tempMediaModelId: null,
  fileLoaderProps: {},
  companyFullName: '',
  companyCars: [],
  companyDrivers: [],
  legalCheckStatus: null,
  legalCheckUpdated: false,
  isClientPage: false,
  companyCurrentTab: 'client',
  legalCheckComments: [],
  isTableLoading: false,
});

export const getters = {
  list: state => state.companiesData.data,
  pagination: state => state.companiesData.meta,
  filtersLabels: state => state.filtersLabels.labels.company,
  legalCheckList: state => {
    if (state.filtersLabels.labels.company) {
      return Object.values(state.filtersLabels.labels.company.legal_check) || [];
    }

    return [];
  },
  query: state => state.query,
  customerDocModelName: state => state.customerDocModelName,
  companyModelName: state => state.companyModelName,
  customerDocCollectionName: state => state.customerDocCollectionName,
  getTempMediaModelName: state => state.tempMediaModelName,
  getTempMediaModelId: state => state.tempMediaModelId,
  fileLoaderProps: state => state.fileLoaderProps,
  fileStatusLabels: state => state.filtersLabels.labels.statuses.mediaStatuses,
  companyStatuses: state => state.filtersLabels.labels.statuses.companyStatuses,
  companyFullName: state => state.companyFullName,
  companyCars: state => state.companyCars,
  companyDrivers: state => state.companyDrivers,
  legalCheckStatus: state => state.legalCheckStatus,
  legalCheckUpdated: state => state.legalCheckUpdated,
  getLegalCheckComments: state => state.legalCheckComments,
  isClientPage: state => state.isClientPage,
  companyCurrentTab: state => state.companyCurrentTab,
  isTableLoading: state => state.isTableLoading,
};

export const mutations = {
  setData(state, payload) {
    state.companiesData = payload;
  },
  setFiltersLabels(state, payload) {
    state.filtersLabels = payload;
    state.customerDocModelName = payload.labels.models.customerModelName;
    state.customerDocCollectionName = payload.labels.company.media
      && payload.labels.company.media.customer_docs.id;
    state.companyModelName = payload.labels.models.companyModelName;
  },
  saveQuery(state, payload) {
    state.query = payload;
  },
  setTempMedia(state, payload) {
    state.tempMediaModelName = payload.tempMediaModelName;
    state.tempMediaModelId = payload.tempMedia.id;
  },
  setFileLoaderProps(state, payload) {
    state.fileLoaderProps = payload;
  },
  setCompanyFullName(state, payload) {
    state.companyFullName = payload;
  },
  setCompanyCars(state, payload) {
    state.companyCars = payload;
  },
  setCompanyDrivers(state, payload) {
    state.companyDrivers = payload;
  },
  setIsClientPageProp(state, payload) {
    state.isClientPage = payload;
  },
  setCompanyCurrentTab(state, payload) {
    state.companyCurrentTab = payload;
  },
  setLegalCheckStatus(state, payload) {
    state.legalCheckStatus = payload;
  },
  setLegalCheckUpdated(state, payload) {
    state.legalCheckUpdated = payload;
  },
  setLegalCheckComments(state, payload) {
    state.legalCheckComments = payload;
  },
  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },
};

export const actions = {
  async getCompanies({ commit, state }, params = {}) {
    const filters = { ...state.query };
    commit('setTableLoading', true);

    await this.$axios.get('/companies', { params: { ...filters, ...params } })
      .then(({ data }) => {
        commit('setData', data);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async getCompaniesCars({ commit }, { id }) {
    await this.$axios.get('/cars/index', { params: { companyId: id } })
      .then(({ data }) => {
        commit('setCompanyCars', data.cars);
      });
  },

  async getCompaniesDrivers({ commit }, { id }) {
    await this.$axios.get('/drivers/index', { params: { companyId: id } })
      .then(({ data }) => {
        commit('setCompanyDrivers', data.cars);
      });
  },

  async getFiltersLabels({ commit }) {
    await this.$axios.get('/companies/labels')
      .then(({ data }) => {
        commit('setFiltersLabels', data);
      });
  },

  async deleteCompany({ dispatch }, id) {
    await this.$axios.delete(`/companies/${id}`)
      .then(() => {
        dispatch('getCompanies');
      });
  },

  async deleteCustomer({ dispatch }, id) {
    await this.$axios.delete(`/customers/delete/${id}`)
      .then(() => {
        dispatch('getCompanies');
      });
  },

  async getTemporaryMediaData({ commit }) {
    await this.$axios.get('/media/temp').then(({ data }) => {
      commit('setTempMedia', data);
    });
  },

  async changeStatus({ dispatch }, payload) {
    await this.$axios.post(`/companies/${payload.modelId}/status`, payload);
  },

  async updateLegalCheckStatus({ commit }, payload) {
    await this.$axios.post(`/companies/${payload.id}/legal-check-status`, {
      legal_check_status: payload.status,
      comment: payload.comment,
    })
      .then(() => {
        commit('setLegalCheckStatus', payload.status);
        commit('setLegalCheckUpdated', true);
      })
      .catch(err => {
        console.log(err);
      });
  },

  async fetchLegalCheckComments({ commit }, payload) {
    await this.$axios.get(`/companies/${payload.companyId}/legal-check-comments?type=${payload.type}`)
      .then(({ data }) => {
        commit('setLegalCheckComments', data.legal_check_comments);
      })
      .catch(err => {
        console.log(err);
      });
  },

  async postLegalCheckComment({ commit }, payload) {
    await this.$axios.post(`/companies/${payload.companyId}/legal-check-comments`, {
      ...payload,
    })
      .catch(err => {
        console.log(err);
      });
  },

  saveQuery({ commit, dispatch }, payload) {
    commit('saveQuery', payload);
  },
};
