export const state = () => ({
  isLoginControlLoading: false,
  isLogoutControlLoading: false,
});

export const getters = {
  GET_CONTROL_LOADING: state => state.isLoginControlLoading,
  GET_LOGOUT_CONTROL_LOADING: state => state.isLogoutControlLoading,
};

export const actions = {
  LOG_IN({ commit }, payload) {
    commit('setControlLoading', true);
    return this.$axios.post('/login', { ...payload })
      .then(({ data }) => {
        commit('setControlLoading', false);
        return data;
      })
      .finally(() => {
        commit('setControlLoading', false);
      });
  },

  LOG_OUT({ commit }) {
    commit('setLogoutControlLoading', true);
    return this.$axios.post('/logout')
      .then(({ data }) => {
        commit('setLogoutControlLoading', true);
        return data;
      })
      .finally(() => {
        commit('setLogoutControlLoading', true);
      });
  },
};

export const mutations = {
  setControlLoading(state, payload) {
    state.isLoginControlLoading = payload;
  },

  setLogoutControlLoading(state, payload) {
    state.isLogoutControlLoading = payload;
  },
};
