export default {
  state: () => ({}),
  getters: {
    userPermissions: (_state, _getters, rootState) => rootState.main.currentUser.permissions || [],
    viewPermissions: (_state, getters) => getters.userPermissions.filter(permission => permission.action === 'view'),
    editPermissions: (_state, getters) => getters.userPermissions.filter(permission => permission.action === 'edit'),
    canView: (_state, getters) => sectionName => {
      return getters.viewPermissions.some(permission => permission.section === sectionName);
    },
    canEdit: (_state, getters) => sectionName => {
      return getters.editPermissions.some(permission => permission.section === sectionName);
    },

    isViewUser: (_state, getters) => getters.canView('users'),
    isEditUser: (_state, getters) => getters.canEdit('users'),

    isViewCars: (_state, getters) => getters.canView('cars'),
    isEditCars: (_state, getters) => getters.canEdit('cars'),

    isViewDrivers: (_state, getters) => getters.canView('drivers'),
    isEditDrivers: (_state, getters) => getters.canEdit('drivers'),

    isViewTransporter: (_state, getters, _rootState, rootGetters) => {
      const isTransporter = rootGetters['main/isTransporter'];
      const hasPermission = getters.canView('transporters');

      return !isTransporter && hasPermission;
    },
    isEditTransporter: (_state, getters, _rootState, rootGetters) => {
      const isTransporter = rootGetters['main/isTransporter'];
      const hasPermission = getters.canEdit('transporters');

      return !isTransporter && hasPermission;
    },

    isViewProfile: (_state, getters, _rootState, rootGetters) => {
      const isAdmin = rootGetters['main/isAdmin'];
      const isCompanyEmployee = rootGetters['main/isCompanyEmployee'];
      const hasPermission = getters.canView('companies');

      return !isAdmin && !isCompanyEmployee && hasPermission;
    },
    isEditProfile: (_state, getters, _rootState, rootGetters) => {
      const isAdmin = rootGetters['main/isAdmin'];
      const isCompanyEmployee = rootGetters['main/isCompanyEmployee'];
      const hasPermission = getters.canEdit('companies');

      return !isAdmin && !isCompanyEmployee && hasPermission;
    },

    isViewCompanies: (_state, getters, _rootState, rootGetters) => {
      const isAdmin = rootGetters['main/isAdmin'];
      const isCompanyEmployee = rootGetters['main/isCompanyEmployee'];

      if (isAdmin || isCompanyEmployee) {
        return getters.canView('companies');
      }

      return getters.canView('transporters');
    },
    isEditCompanies: (_store, getters, _rootStore, rootGetters) => {
      const isAdmin = rootGetters['main/isAdmin'];
      const isCompanyEmployee = rootGetters['main/isCompanyEmployee'];

      if (isAdmin || isCompanyEmployee) {
        return getters.canEdit('companies');
      }

      return getters.isEditTransporter;
    },

    isViewSettings: (_state, getters, _rootState, rootGetters) => {
      const isTransporter = rootGetters['main/isTransporter'];
      const hasPermission = getters.canView('settings');

      return !isTransporter && hasPermission;
    },
    isEditSettings: (_state, getters, _rootState, rootGetters) => {
      const isTransporter = rootGetters['main/isTransporter'];
      const hasPermission = getters.canEdit('settings');

      return !isTransporter && hasPermission;
    },

    isViewQuotas: (_store, getters) => getters.canView('quotas'),
    isEditQuotas: (_store, getters) => getters.canEdit('quotas'),

    isViewTimeslots: (_store, getters) => getters.canView('timeslots'),
    isEditTimeslots: (_store, getters) => getters.canEdit('timeslots'),

    isViewQueue: (_store, getters) => getters.canView('queue_unloading'),
    isEditQueue: (_store, getters) => getters.canEdit('queue_unloading'),
  },
  mutations: {},
  actions: {},
};
