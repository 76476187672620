<template>
  <div
    class="t-uploader-inner"
    @dragover.prevent="$emit('dragover', $event)"
    @dragleave="$emit('dragleave', $event)"
    @drop.stop.prevent="dropFiles"
    @click="openFileLoader"
  >
    <input
      ref="file"
      :accept="getFileTypes(fileType)"
      :value="inputValue"
      :multiple="isMultiple"
      type="file"
      style="display: none"
      @input="inputChange"
    >
    <slot :file="processedFile" />
    <slot
      name="multiple"
      :files="processedFiles"
    />
  </div>
</template>

<script>
/* eslint no-param-reassign: 0 */
import { v4 as uuid } from 'uuid';

export default {
  name: 'TUploader',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    isDnd: {
      type: Boolean,
      default: false,
    },

    route: {
      type: String,
      required: true,
    },

    method: {
      type: String,
      required: true,
    },

    headers: {
      type: Object,
      default: () => ({}),
    },

    isMultiple: {
      type: Boolean,
      default: false,
    },

    isAutoUpload: {
      type: Boolean,
      default: true,
    },

    fieldName: {
      type: String,
      default: 'file',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    addData: {
      type: Object,
      default: () => ({}),
    },

    hasPreview: {
      type: Boolean,
      default: false,
    },

    fileType: {
      type: String,
      default: 'any',
    },

    // В MB
    maxSize: {
      type: Number,
      default: 100,
    },
  },

  data: () => ({
    files: [],
    workingThreads: 0,
    uploadSize: 0,
    uploadedSize: 0,
    inputValue: '',
  }),

  computed: {
    processedFiles() {
      return [...this.files, ...this.uploadedFiles, {}];
    },

    processedFile() {
      return this.processedFiles[0];
    },

    canUpload() {
      return this.isUploading !== true;
    },

    canAddFiles() {
      return ((!this.isUploading
               && (!this.files.length && !this.value.length))
              || this.isMultiple) && !this.disabled;
    },

    isUploading() {
      return this.workingThreads > 0;
    },

    uploadedFiles() {
      return this.processFiles(this.value, { isQueued: false, progress: 100, uploaded: true });
    },
  },

  watch: {
    isUploading(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.$emit('start');
      } else if (oldVal === true && newVal === false) {
        this.$emit('finish');
      }
    },
  },

  mounted() {
    window.addEventListener('drop', this.handleDocumentDrop, false);
    window.addEventListener('dragover', this.handleDocumentDrop, false);
  },

  beforeDestroy() {
    this.abort();
    window.removeEventListener('drop', this.handleDocumentDrop, false);
    window.removeEventListener('dragover', this.handleDocumentDrop, false);
  },

  methods: {
    handleDocumentDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },

    openFileLoader() {
      if (this.canAddFiles) {
        this.$refs.file.click();
      }

      this.$emit('click');
    },

    abort() {
      this.files.forEach(file => {
        if (typeof file.abort === 'function') {
          file.abort();
        }
      });
    },

    processFiles(files, options = {}) {
      if (!this.isMultiple && files.length > 1) {
        return [{
          id: files[0].id || uuid(),
          data: files[0],
          isQueued: true,
          total: files[0].size,
          loaded: 0,
          progress: 0,
          uploaded: false,
          ...options,
        }];
      }

      return files.map(file => ({
        id: file.id || uuid(),
        data: file,
        isQueued: true,
        total: file.size,
        loaded: 0,
        progress: 0,
        uploaded: false,
        ...options,
      }));
    },

    dropFiles(e) {
      this.$emit('drop', e);
      if (this.canAddFiles) {
        this.addFiles(Array.from(e.dataTransfer.files));
      }
    },

    inputChange(e) {
      if (this.canAddFiles) {
        this.addFiles(Array.from(e.target.files));
        this.inputValue = '';
      }
    },

    addFiles(fileList) {
      if (fileList && this.canAddFiles) {
        this.files = [
          ...this.files,
          ...this.processFiles(fileList),
        ];

        if (this.isAutoUpload) {
          this.upload();
        }
      }
    },

    removeFile(fileId) {
      const fileIndex = this.files.findIndex(file => file.id === fileId);

      this.files.splice(fileIndex, 1);
    },

    async upload() {
      if (!this.canUpload) {
        return;
      }
      const files = this.files.map(item => ({ ...item }));
      // Отправка файлов по очереди, а не N Запросов одновременно
      for (const file of files) {
        if (file.isQueued) {
          file.isQueued = false;
          await this.uploadFile(file);
        }
      }

      // Синхронная отправка файлов

      // this.files.forEach((file, index) => {
      //   file.isQueued = false;
      //   this.uploadFile(file);
      // });
    },

    async uploadFile(file) {
      this.workingThreads += 1;

      const form = new FormData();

      // Ограничение по размеру файла
      if (this.maxSize && file.data.size >= (this.maxSize * 1048576)) {
        return;
      }

      form.append(this.fieldName, file.data, file.data.name);

      const { CancelToken } = this.$axios;
      const headers = {};

      Object.assign(headers, {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json, text/plain, */*',
        'Access-Control-Allow-Origin': '*',
      });

      if (this.hasPreview) {
        Object.keys(this.addData).forEach(key => {
          form.append(key, this.addData[key]);
        });
      }

      await this.$axios({
        method: this.method,
        url: this.route,
        data: form,
        headers,
        onUploadProgress: e => {
          file.total = e.total;
          file.loaded = e.loaded;
          file.progress = 100 * e.loaded / e.total;
          if (e.loaded >= e.total) {
            this.workingThreads -= 1;
          }
        },
        cancelToken: new CancelToken(abort => {
          file.abort = remove => {
            if (remove) {
              this.removeFile(file.id);
            }
            abort();
          };
        }),
      }).then(response => {
        this.$emit('response', {
          response,
          file: {
            ...file,
            data: {
              name: file.data.name,
            },
          },
        });
        this.removeFile(file.id);
      }).catch(error => {
        this.workingThreads -= 1;
        this.$emit('error', error);
      });
    },

    getFileTypes(type) {
      switch (type) {
        case 'imageOrPdf': {
          return '.pdf,.jpg,.jpeg,.png,.gif,.jfif,.webp';
        }
        default: {
          return '.pdf,.txt,.jpg,.jpeg,.png,.jfif,.doc,.xls,.xlsx';
        }
      }
    },
  },
};
</script>
