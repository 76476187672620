<template>
  <div class="mb-3 document-viewer-info-row">
    <div class="document-viewer-info-row__label">
      {{label}}
    </div>
    <div class="document-viewer-info-row__text">
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentViewerInfoRow',

  props: {
    label: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/scss/variables';

.document-viewer-info-row {
  &__label {
    color: $steel-grey;
  }
}
</style>
