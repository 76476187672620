<template>
  <svg-icon
    v-bind="{...$attrs}"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'TIconSvg',
};
</script>
