import { parsePreviewFileLinks } from '~/assets/js/utils';

export const state = () => ({
  driversData: [],
  tablePagination: {},
  licenseTypes: [],
  formData: {},
  labels: {},
  query: {},
  tempMediaModelName: '',
  tempMediaModelId: null,
  history: [],
  entrepreurerDriverData: {},
  isTableLoading: false,
});

export const getters = {
  list: state => state.driversData,
  pagination: state => state.tablePagination,
  licenseTypes: state => state.licenseTypes,
  formData: state => state.formData,
  query: state => state.query,
  labels: state => state.labels,
  getTempMediaModelName: state => state.tempMediaModelName,
  getTempMediaModelId: state => state.tempMediaModelId,
  getHistory: state => state.history,
  getEntrepreurerDriverData: state => state.entrepreurerDriverData,
  isTableLoading: state => state.isTableLoading,
  tmsEdditableFields: state => state.formData.tmsEdditableFields || [],
  // eslint-disable-next-line no-shadow
  hasEdditableFields: (_state, getters, _rootState, rootGetters) => {
    return !rootGetters['main/isConnectedToCore'] || getters.tmsEdditableFields.length > 0;
  },
};

export const mutations = {
  setData(state, payload) {
    state.driversData = payload;
  },

  setQuery(state, payload) {
    state.query = payload;
  },

  setPagination(state, payload) {
    state.tablePagination = payload;
  },

  setLicenseTypes(state, payload) {
    state.licenseTypes = payload;
  },

  setLabels(state, payload) {
    state.labels = payload;
  },

  setFormData(state, { driver, media, allowed_for_edit_fields }) {
    state.formData = {
      tmsEdditableFields: allowed_for_edit_fields,
      driverName: driver.profile.fullName,
      formFields: {
        ...driver.profile,
        ...driver.transporters[0].pivot,
        created_by: driver.created_by,
        created_at: driver.created_at,
        status: driver.status,
        transporter: driver.transporters[0],
        userId: driver.id,
        companyId: driver.transporters[0].id,
        phone: driver.phone,
        license_types: driver.license_types,
        isViwedComment: driver.isViwedComment,
        countComment: driver.countComment,
        passport_main: parsePreviewFileLinks(media.passport_main_spread_docs || []),
        passport_secondary: parsePreviewFileLinks(media.passport_registration_docs || []),
        license_scans: parsePreviewFileLinks(media.license_docs || []),
        medical_scans: parsePreviewFileLinks(media.medical_docs || []),
        base_for_work_docs: parsePreviewFileLinks(media.base_for_work_docs || []),
        SZVM_docs: parsePreviewFileLinks(media.SZVM_docs || []),
        carIds: driver.cars.map(({ id, number }) => ({ id, name: number })),
      },
    };
  },

  setEntrePreuerDriverData(state, { driver, media }) {
    state.entrepreurerDriverData = {
      ...driver,
      birth_date: driver.passport_birthday,
      passport_serial: driver.passport_number,
      passport_issued_date: driver.passport_date,
      passport_issued_by_code: driver.passport_issued_by_code,
      passport_issued_by_name: driver.passport_issued_by_name,
      registration_place: driver.passport_registration_address,
      passport_main: parsePreviewFileLinks(media.passport_main_spread_docs || []),
      passport_secondary: parsePreviewFileLinks(media.passport_registration_docs || []),
    };
  },

  setTempMedia(state, payload) {
    state.tempMediaModelName = payload.tempMediaModelName;
    state.tempMediaModelId = payload.tempMedia.id;
  },

  setHistory(state, payload) {
    state.history = payload;
  },

  setCommentStatus(state, driverId) {
    const currentDriver = state.driversData.find(driver => driver.id === driverId);

    if (currentDriver) {
      currentDriver.isViwedComment = false;
    }
  },

  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },
};

export const actions = {
  async getDrivers({ commit, state }, params) {
    commit('setTableLoading', true);

    const filters = { ...state.query };

    await this.$axios.get('/drivers/index', { params: { ...filters, ...params } })
      .then(({ data }) => {
        commit('setData', data.data);
        commit('setPagination', data.meta);
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async getDriver({ commit }, { driverId, companyId }) {
    await this.$axios.get(`/drivers/${driverId}/${companyId}`)
      .then(({ data }) => {
        commit('setFormData', data);
      });
  },

  async loadHistory({ commit }, { driverId, companyId }) {
    await this.$axios.get(`/drivers/${driverId}/${companyId}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);
      });
  },

  async searchDrivers({ commit }, searchString) {
    commit('setTableLoading', true);

    await this.$axios.get('/drivers/index', { params: { search: searchString } })
      .then(({ data }) => {
        commit('setData', data.data);
        commit('setPagination', data.meta);
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async create({ dispatch }, formData) {
    await this.$axios.post('/drivers/create', { ...formData })
      .then(({ data }) => {
        dispatch('getDrivers');
        return data.success;
      });
  },

  async update({ dispatch }, formData) {
    await this.$axios.post('/drivers/update', { ...formData })
      .then(({ data }) => {
        if (!formData.fromCompany) {
          dispatch('getDrivers');
        }
        return data.success;
      });
  },

  async delete({ commit }, { userId, companyId }) {
    await this.$axios.post('/drivers/delete', { userId, companyId })
      .then(({ data }) => data.success);
  },

  async getLicenseTypes({ commit }) {
    await this.$axios.get('/drivers/license-labels')
      .then(({ data }) => {
        commit('setLabels', data);
        commit('setLicenseTypes', data.labels);
      });
  },

  async getTemporaryMediaData({ commit }) {
    await this.$axios.get('/media/temp').then(({ data }) => {
      commit('setTempMedia', data);
    });
  },

  async changeStatus({ dispatch }, payload) {
    await this.$axios.post(`/drivers/${payload.modelId}/status`, payload)
      .catch(err => {
        console.log(err);
      });
  },

  async saveQuery({ commit, dispatch }, payload) {
    await commit('setQuery', payload);
  },

  async fetchEntrepreneurData({ commit }, payload) {
    await this.$axios.post(`/companies/${payload.id}/getEntrepreneur`, {
      modelName: payload.modelName,
      modelId: payload.modelId,
    })
      .then(({ data }) => {
        commit('setEntrePreuerDriverData', data.data);
      });
  },
};
