import cookieUniversal from 'cookie-universal';

export default ({ req, res }, inject) => {
  const options = {
    alias: 'cookies',
    parseJSON: true,
  };

  const createdCookieUniversal = cookieUniversal(req, res, options.parseJSON);

  inject(options.alias, createdCookieUniversal);
};
