import {
  formatPhone,
} from '~/assets/js/utils';

export const state = () => ({
  subQuotaData: {},
  assignedTypes: [],
  quotaData: {},
  usersList: [],
});

export const getters = {
  getQuotaData: state => state.quotaData,
  getSubQuotaData: state => state.subQuotaData,
  getAssignedTypes: state => state.assignedTypes,
  getUsersList: state => state.usersList,
};

export const mutations = {
  setSubQuotaData(state, payload) {
    state.subQuotaData = payload;
  },

  setAssignedTypes(state, payload) {
    state.assignedTypes = Object.values(payload);
  },

  setQuotaData(state, payload) {
    state.quotaData = payload;
  },

  setUserList(state, payload) {
    state.usersList = payload.map(user => ({
      ...user,
      name: user.profile.name ? user.profile.fullName : formatPhone(user.phone),
    }));
  },
};

export const actions = {
  async loadLabels({ commit }) {
    await this.$axios.get('/subQuotas/create')
      .then(({ data }) => {
        commit('setAssignedTypes', data.assigned_type);
      })
      .catch(error => console.log(error));
  },

  async loadSubQuota({ commit }, id) {
    await this.$axios.get(`/subQuotas/${id}/edit`)
      .then(({ data }) => {
        commit('setSubQuotaData', data.quota);
        return data;
      })
      .catch(error => console.log(error));
  },

  async loadCompanyUsers({ commit }, id = 2) {
    await this.$axios.get(`/companies/${id}/users`)
      .then(({ data }) => {
        commit('setUserList', data.users);
        return data;
      })
      .catch(error => console.log(error));
  },

  createSubQouta({ dispatch }, data) {
    return this.$axios.post('/subQuotas', data)
      .then(() => true);
  },

  updateSubQouta({ dispatch }, data) {
    return this.$axios.put(`/subQuotas/${data.id}`, data)
      .then(() => true);
  },

  deleteSubQouta({ dispatch }, id) {
    return this.$axios.delete(`/subQuotas/${id}`)
      .then(() => true)
      .catch(err => {
        console.log(err);
        return false;
      });
  },
};
