<template>
  <div>
    <slot />
    <TToast
      v-if="toastMessage"
      :with-timer="toastMessage.withTimer"
      :timer-seconds="toastMessage.timerSeconds"
      @close="hideToastMessage"
    >
      <div slot="body">
        <div v-html="toastMessage.html" />
      </div>
    </TToast>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AppEntry',

  computed: {
    ...mapGetters({
      toastMessage: 'general/getToastMessage',
      currentUser: 'main/getCurrentUser',
    }),
  },

  created() {
    if (this.currentUser) {
      this.$bugsnag?.addMetadata('user', {
        id: this.currentUser.id,
        phone: this.currentUser.phone,
        locale: this.currentUser.locale,
        fullName: this.currentUser.profile?.fullName,
      });

      this.$bugsnag?.addMetadata('company', {
        id: this.currentUser.active_company_id,
        name: this.currentUser.company?.name,
      });
    }
  },

  methods: {
    ...mapMutations({
      hideToastMessage: 'general/hideToastMessage',
    }),
  },
};
</script>
