import model from '~/assets/js/model';

const toastMessageModel = model({
  html: '',
  timeout: undefined, // seconds
  withTimer: undefined,
  height: undefined,
});

export const state = () => ({
  token: null,
  errorMessageToShow: '',
  isMenuShrunk: false,
  isNdsPrice: true,
  toastMessage: null,
});

export const getters = {
  GET_TOKEN: state => state.token,
  GET_ERROR_MSG: state => state.errorMessageToShow,
  IS_MENU_SHRUNK: state => state.isMenuShrunk,
  isNdsPrice: state => state.isNdsPrice,
  getToastMessage: state => state.toastMessage,
  isLoggedIn: state => Boolean(state.token),
};

export const actions = {
  SET_AUTH_COOKIE({ commit }, token) {
    this.$cookies.set('token', token, { maxAge: 30 * 24 * 3600, path: '/' });

    commit('setToken', token);
  },

  REMOVE_AUTH_COOKIE({ commit }) {
    this.$cookies.remove('token');

    commit('setToken', null);
  },

  SET_AUTH_HEADER(_, token) {
    this.$axios.setAuthHeader(token);
  },

  REMOVE_AUTH_HEADER() {
    this.$axios.setAuthHeader(null);
  },

  TOGGLE_MENU_SHRUNK({ commit }, value) {
    commit('setMenuShrunk', value);
  },

  SET_NDS_VALUE({ commit }, value) {
    this.$cookies.set('nds', value);
    commit('setNdsValue', value);
  },
};

export const mutations = {
  setToken(state, payload) {
    state.token = payload;
  },

  setErrorMessage(state, payload) {
    state.errorMessageToShow = payload;
  },

  setMenuShrunk(state, payload) {
    state.isMenuShrunk = payload;
  },

  setNdsValue(state, payload) {
    state.isNdsPrice = payload;
  },

  /**
   * @param {String|Object} payload
   */
  showToastMessage(state, payload) {
    const toastObject = typeof payload === 'string' ? { html: payload } : payload;

    state.toastMessage = toastMessageModel(toastObject);
  },

  hideToastMessage(state, payload) {
    state.toastMessage = null;
  },
};
