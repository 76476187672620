export const regex = {
  phoneRegex: /^((7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
};

export const RELOAD_TRIGGER = 'reloadTrigger';

export const I18N_COOKIE = 'i18n_locale';

// Ограничение квотами (из настроек)
export const LOADING_DQ_ID = 1;
export const UNLOADING_DQ_ID = 2;

// Фильтр квот
export const UNIT_TYPE_TRIPS = 1;

export const historyLogTypes = {
  WEB: 'web',
  MOBILE: 'mobile',
};

export const QUANTITY_FOR_TRIPS_LABEL = 25;

// Контрагенты
export const LEGAL_ENTITY_TYPE = 1;
export const PHYSICAL_ENTITY_TYPE = 2;

// Модалка доступов
export const HIDDEN_SECTIONS_FOR_TRANSPORTER = ['settings', 'transporters', 'queue_unloading'];
export const DISABLED_TRANSPORTER_EDIT_ACCESSES = ['quotas', 'settings'];
export const DISABLED_CLIENT_EDIT_ACCESSES = ['quotas'];
export const DISABLED_COMPANY_EMPLOYEE_EDIT_ACCESS = ['settings', 'users'];

// Настройки
export const DEFAULT_PRODUCT_STATUS = {
  id: 3,
};

// Форма рейсов
export const STATUS_ALIASES_WITH_WEIGHT = ['arrived', 'loaded', 'unloaded'];

// Queue page
export const QUEUE_CHIP_STATUS_COLORS = {
  EARLIER: '#1BA0DE',
  LATER: '#CB1939',
  ON_TIME: '#399E50',
  CALLED: '#5E6979',
};
