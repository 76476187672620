var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nuxt-link',{attrs:{"to":_vm.link,"exact":_vm.link === '/'},scopedSlots:_vm._u([{key:"default",fn:function({ isActive, navigate, href }){return [_c('li',{staticClass:"sidepanel-menu-item",class:{ 'sidepanel-menu-item--active': isActive
      || href.includes('users')
      && _vm.$route.name
      && _vm.$route.name.includes('users')}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:({
        content: _vm.isShrunk ? _vm.text : '',
        classes: 'sidepanel-menu-item__tooltip',
      }),expression:"{\n        content: isShrunk ? text : '',\n        classes: 'sidepanel-menu-item__tooltip',\n      }",modifiers:{"top-end":true}}],staticClass:"sidepanel-menu-item__inner",class:{'sidepanel-menu-item__inner--shrunk': _vm.isShrunk},attrs:{"href":href},on:{"click":navigate}},[_c('TIconSvg',{staticClass:"sidepanel-menu-item__icon",class:{
          'sidepanel-menu-item__icon--active': isActive,
          'sidepanel-menu-item__icon--shrunk': !_vm.isShrunk
        },attrs:{"name":_vm.icon,"width":_vm.iconWidth,"height":_vm.iconHeight,"view-box":_vm.iconViewBox,"stroke":_vm.iconStroke}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShrunk),expression:"!isShrunk"}],staticClass:"sidepanel-menu-item__title"},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")])],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }