<template>
  <AppEntry>
    <div class="class">
      <div class="sidebar-layout">
        <Sidepanel />
        <div class="sidebar-layout__content">
          <DocumentViewer />
          <nuxt />
        </div>
      </div>
    </div>
  </AppEntry>
</template>

<script>
import AppEntry from '~/AppEntry';
import Sidepanel from '../components/Features/Sidepanel';
import DocumentViewer from '~/components/Features/DocumentViewer/DocumentViewer';

export default {
  components: {
    AppEntry,
    DocumentViewer,
    Sidepanel,
  },
};
</script>

<style lang="scss">
.sidebar-layout {
  display: flex;
  overflow: auto;

  &__content {
    overflow: auto;
    position: relative;
    flex: 1;
  }
}
</style>
