export const state = () => ({
  isPasswordRecoveryControlLoading: false,
  isPasswordRecoveryPhoneSent: false,
  isVerifyPasswordRecoveryControlLoading: false,
  isPasswordRecoveryVerified: false,
  isCreateNewPasswordControlLoading: false,
});

export const getters = {
  GET_PR_CONTROL_LOADING: state => state.isPasswordRecoveryControlLoading,
  GET_PR_PHONE_SENT: state => state.isPasswordRecoveryPhoneSent,
  GET_VPR_CONTROL_LOADING: state => state.isVerifyPasswordRecoveryControlLoading,
  GET_PR_VERIFIED: state => state.isPasswordRecoveryVerified,
  GET_CNP_CONTROL_LOADING: state => state.isCreateNewPasswordControlLoading,
};

export const actions = {
  SEND_PASSWORD_RECOVERY_PHONE({ commit }, payload) {
    commit('setPRControlLoading', true);
    return this.$axios.post('/password/forget', { ...payload })
      .then(data => {
        commit('setPRControlLoading', false);
        commit('setPRPhoneSent', true);
        return data;
      })
      .finally(() => {
        commit('setPRControlLoading', false);
      });
  },

  VERIFY_PASSWORD_RECOVERY({ commit }, payload) {
    commit('setVPRControlLoading', true);
    return this.$axios.post('/password/confirm', { ...payload })
      .then(({ data }) => {
        commit('setVPRControlLoading', false);
        commit('setPRVerified', true);
        return data;
      })
      .finally(() => {
        commit('setVPRControlLoading', false);
      });
  },

  CREATE_NEW_PASSWORD({ commit }, payload) {
    commit('setCNPControlLoading', true);
    return this.$axios.post('/password/reset', { ...payload })
      .then(({ data }) => {
        commit('setCNPControlLoading', false);
        return data;
      })
      .finally(() => {
        commit('setCNPControlLoading', false);
      });
  },
};

export const mutations = {
  setPRControlLoading(state, payload) {
    state.isPasswordRecoveryControlLoading = payload;
  },

  setPRPhoneSent(state, payload) {
    state.isPasswordRecoveryPhoneSent = payload;
  },

  setVPRControlLoading(state, payload) {
    state.isVerifyPasswordRecoveryControlLoading = payload;
  },

  setPRVerified(state, payload) {
    state.isPasswordRecoveryVerified = payload;
  },

  setCNPControlLoading(state, payload) {
    state.isCreateNewPasswordControlLoading = payload;
  },
};
