export const dict = {
  custom: {
    phone: {
      regex: () => 'Please enter a valid phone number',
    },
    responsible_person_phone: {
      regex: () => 'Please enter a valid phone number',
    },
    number: {
      regex: () => 'Please enter a valid number',
    },
    password: {
      min: () => 'Wrong password',
    },
    sts: {
      required: () => 'Add file',
    },
    docs: {
      required: () => 'Add file',
    },
    agreement_documents: {
      required: () => 'Upload file',
    },
    instant_price: {
      min_value: (field, [min, value]) => `The rate must be higher than ${min - 1}${value || ''}`,
    },
    generalLimit: {
      max_value: (field, [max, value]) => (!max
        ? 'Indicate the amount of cargo'
        : `The field must be ${max} ${value || ''} or less`),
    },
  },
};

export default {
  after: (field, [target]) => `The field must have a date after ${target}`,
  alpha_dash: () => 'The field can only contain letters, numbers and a hyphen',
  alpha_num: () => 'The field can only contain letters and numbers',
  alpha_spaces: () => 'The field can only contain letters and spaces',
  alpha: () => 'The field can only contain letters',
  before: (field, [target]) => `The field must have a date before ${target}`,
  between: (field, [min, max]) => `The field must be at least ${min} characters long but not more then ${max}`,
  confirmed: (field, [confirmedField]) => `Field does not match ${confirmedField}`,
  date_between: (field, [min, max]) => `The field must be between ${min} and ${max}`,
  date_format: (field, [format]) => `The field must be in the format ${format}`,
  decimal: () => 'The field can be numeric or floating point',
  digits: () => 'The field must be numeric',
  dimensions: (field, [width, height]) => `The margin must be ${width} pixels by ${height} pixels`,
  email: () => 'Please enter a valid email',
  phone: () => 'Please enter a valid phone number',
  ext: () => 'The field must be a valid file',
  image: () => 'The field must be an image',
  in: () => 'The field must be a valid value',
  ip: () => 'The field must be a valid IP address',
  max: (field, [length]) => `The field must be not more then ${length} characters long`,
  max_value: (field, [max, value]) => `The field must be ${max} ${value || ''} or less`,
  mimes: () => 'The field must be a valid file type',
  min: (field, [length]) => `The field must be at least ${length} characters long`,
  min_value: (field, [min, value]) => `The field must be ${min} ${value || ''} or more`,
  not_in: () => 'The field must be a valid value',
  numeric: () => 'The field must be a number',
  regex: () => 'The format is invalid',
  required: () => 'Please fill in the field',
  size: (field, [size]) => `The field must be less than ${size} KB`,
  url: () => 'Enter the correct URL',
  length: (field, [size]) => `The field must contain ${size} characters`,
  inn: (field, [innLength1, innLength2]) => `The field must contain ${innLength1} or ${innLength2} digits`,
  items: () => 'Select an item from the list',
  time_period: () => 'End time must be later than start time',
  max_integer_value: (field, [max, value]) => `The field must be ${max} ${value || ''} or less`,
  min_integer_value: (field, [min, value]) => `The field must be ${min} ${value || ''} or more`,
  loading_restriction: (field, [max]) => (Number(max) === 0
    ? 'The value of the maximum daily loading has been exceeded'
    : `The amount of cargo must be ${max} or less`),
};
