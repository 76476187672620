<template>
  <div
    :class="{
      't-field--no-label': !label,
      't-field--loading': loading,
      't-datepicker--has-warning': warning,
      't-datepicker--has-error': error,
      't-datepicker--has-value': value,
    }"
    class="t-field t-datepicker"
  >
    <span
      v-if="label"
      :class="{
        't-field__label--error': hasErrors,
        't-field__label--success': !hasErrors && hasSuccessMessages,
      }"
      class="t-field__label"
    >
      {{label}}
    </span>
    <div class="t-field__group t-datepicker__picker-wrapper">
      <TButton
        v-if="hasArrows"
        class="t-datepicker__picker-arrow t-datepicker__picker-arrow--left"
        :class="{'t-datepicker__picker-arrow--error': hasErrors}"
        icon="arrow-left"
        secondary-action
        disable-lover
        icon-width="7"
        icon-height="12"
        icon-view-box="0 0 7 12"
        @click="setDay('prev')"
      />
      <div
        class="t-datepicker__input-wrapper"
        :class="{
          't-datepicker__input-wrapper--error': hasErrors
        }"
      >
        <TIconSvg
          name="calendar"
          view-box="0 0 14 15"
          width="13"
          height="15"
          class="t-datepicker__icon"
        />
        <Datepicker
          ref="calendar"
          :value="value"
          :config="localConfig"
          :placeholder="placeholder"
          :disabled="disabled || loading"
          class="t-field t-datepicker__input"
          @input="handleDateInput"
          @on-close="onCalendarClose"
        />
      </div>
      <TButton
        v-if="hasArrows"
        class="t-datepicker__picker-arrow t-datepicker__picker-arrow--right"
        :class="{'t-datepicker__picker-arrow--error': hasErrors}"
        icon="arrow-right"
        secondary-action
        disable-lover
        icon-width="7"
        icon-height="12"
        icon-view-box="0 0 7 12"
        @click="setDay('next')"
      />
      <div
        v-if="warning || error"
        class="t-datepicker__tooltip j-error"
      >
        <TIconSvg
          v-tooltip="error || warning"
          name="tooltip"
          view-box="0 0 15 15"
          class="ml-2 t-datepicker__tooltip-icon"
        />
      </div>
    </div>
    <div
      v-if="hasErrors"
      class="error-text j-error t-datepicker__error"
    >
      {{errorMessages[0]}}
    </div>
  </div>
</template>

<script>
// libs
import moment from 'moment';
// Utils
import { isEmpty } from 'chober';

// Components
import Datepicker from 'vue-flatpickr-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.min.css';

const DEFAULT_CONFIG = {
  altInput: true,
  dateFormat: 'Y-m-d',
  disableMobile: true,
};

const defaultMode = 'single';

export default {
  name: 'TDatepicker',

  components: {
    Datepicker,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      default: false,
    },

    allowInput: {
      type: Boolean,
      default: true,
    },

    warning: {
      type: String,
      default: '',
    },

    error: {
      type: String,
      default: '',
    },

    minDate: {
      type: String,
      default: '',
    },

    maxDate: {
      type: String,
      default: '',
    },

    defaultDate: {
      type: String,
      default: '',
    },

    altFormat: {
      type: String,
      default: 'd.m.Y',
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    successMessages: {
      type: [Array, String],
      default: '',
    },

    mode: {
      type: String,
      default: defaultMode,
    },

    hasArrows: {
      type: Boolean,
      default: false,
    },

    dateFormat: {
      type: String,
      default: 'Y-m-d',
    },
  },

  data: () => ({
    selectedLocale: '',
  }),

  computed: {
    defaultConfig() {
      return {
        altInput: true,
        dateFormat: this.dateFormat,
        locale: this.selectedLocale !== 'en' ? require(`flatpickr/dist/l10n/${this.selectedLocale}.js`).default[this.selectedLocale] : 'en',
        disableMobile: true,
      };
    },

    localConfig() {
      return {
        ...this.defaultConfig,
        altFormat: this.altFormat,
        defaultDate: this.defaultDate,
        mode: this.mode,
        minDate: this.minDate,
        maxDate: this.maxDate,
        allowInput: this.allowInput,
      };
    },

    hasErrors() {
      return !isEmpty(this.errorMessages);
    },

    hasSuccessMessages() {
      return !isEmpty(this.successMessages);
    },
  },

  created() {
    this.selectedLocale = this.$i18n.locale;
  },

  mounted() {
    const flatPicker = this.$refs.calendar.$data.fp;

    if (flatPicker.altInput) {
      flatPicker.altInput.addEventListener('input', this.checkDate);
    } else {
      flatPicker.input.addEventListener('input', this.checkDate);
    }
  },

  methods: {
    moment,

    handleDateInput(selectedDate) {
      if (this.allowEmpty && this.value === selectedDate) {
        this.$emit('input', '');
        return;
      }

      this.$emit('input', selectedDate);
    },

    setDay(direction) {
      let date = null;
      const flatPicker = this.$refs.calendar.$data.fp;

      if (direction === 'prev') {
        date = this.moment(this.value).subtract(1, 'day').format('YYYY-MM-DD');
      } else {
        date = this.moment(this.value).add(1, 'day').format('YYYY-MM-DD');
      }

      flatPicker.setDate(date);
      this.$emit('input', date);
    },

    checkDate(event) {
      if (!this.allowInput || this.mode !== defaultMode) {
        return;
      }

      const minDateLength = 10;
      const { value } = event.target;
      const flatPicker = this.$refs.calendar.$data.fp;

      try {
        const normalizedInputDate = flatPicker.formatDate(
          flatPicker.parseDate(value, this.altFormat),
          DEFAULT_CONFIG.dateFormat,
        );

        let normalizedValueDate;

        if (this.value) {
          normalizedValueDate = flatPicker.formatDate(
            flatPicker.parseDate(this.value, DEFAULT_CONFIG.dateFormat),
            DEFAULT_CONFIG.dateFormat,
          );
        }

        if (
          normalizedValueDate !== normalizedInputDate
          && value.length === minDateLength
          && this.value !== normalizedInputDate
        ) {
          const formattedDate = flatPicker.formatDate(
            flatPicker.parseDate(value, this.altFormat),
            DEFAULT_CONFIG.dateFormat,
          );

          flatPicker.setDate(formattedDate);
          this.$emit('input', formattedDate);
        }
      } catch (err) {
        flatPicker.setDate(flatPicker.defaultDate);
      }
    },

    onCalendarClose() {
      const flatPicker = this.$refs.calendar.$data.fp;

      this.$nextTick(() => {
        if (!flatPicker.altInput.value && this.value) {
          flatPicker.altInput.value = flatPicker.formatDate(
            flatPicker.parseDate(this.value, DEFAULT_CONFIG.dateFormat),
            this.altFormat,
          );
        }
      });

      // prevent datepicker opening on file dragging
      document.activeElement.blur();
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';
@import 'assets/scss/_global-classes';

.t-datepicker {
  $self: &;

  color: $dusty-grey;

  &--has-value {
    color: unset;
  }

  &--has-warning {
    #{$self}__input {
      border: 1px solid $honey-yellow;
    }
    #{$self}__tooltip-icon {
      fill: $honey-yellow;
    }
  }

  &--has-error {
    #{$self}__input {
      border: 1px solid $red;
    }
    #{$self}__tooltip-icon {
      fill: $red;
    }
  }

  &__picker {
    &-arrow {
      flex-shrink: 0;

      &--error {
        border-color: $red;
      }

      &--left {
        border-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      &--right {
        border-left: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    &-wrapper {
      align-items: center;
      position: relative;
    }
  }

  &__tooltip {
    position: absolute;
    top: 10px;
    right: -20px;
  }

  &__error {
    line-height: 1.2;
  }

  input {
    border: none;
    padding: 0;
    outline: none;
    padding-left: 35px;
    height: $default-control-height;

    &::placeholder {
      color: $steel-grey;
    }
  }

  &__input-wrapper {
    position: relative;
    width: 100%;

    input {
      border: 1px solid $light-grey;;
    }

    &--error {
      input {
        border-color: $red;
      }
    }
  }

  &__icon {
    @include centerAbsoluteY;
    pointer-events: none;
    left: 10px;
    z-index: 5;
  }

  &__input {
    padding: 0 0 0 35px !important;
  }

  &__tooltip-icon {
    display: block;
  }
}

  input {
    @extend .t-field__field;
    width: initial;
    padding: 0;
    height: auto;
    outline: none;
    padding-left: 35px;

    &::placeholder {
      color: $steel-grey;
    }
  }

.flatpickr-calendar.open {
  width: 265px;
  padding: 18px;
  padding-top: 0;
  line-height: 1.7;
  border-radius: 2px;
  border: 1px solid $light-grey;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0.1);

  &.arrowBottom {
    //position fix
    margin-top: -30px;
  }

  &::before,
  &::after {
    display: none;
  }
}

.flatpickr-months {
  height: 38px;
  padding: 8px 20px;
  position: relative;
  margin-bottom: 10px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 8px;
    right: 8px;
    height: 1px;
    background-color: $light-grey;
  }
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  padding: 0;
  top: 11px;

  svg {
    width: 11px;
    height: 15px;
    fill: none;
    stroke: $almost-black;
    stroke-width: 2;
  }
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 8px;
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 8px;
}

.flatpickr-current-month {
  padding-top: 3px;
  color: $steel-grey;
  font-weight: 600;
  font-size: 1rem;
  height: unset;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-weight: 600;
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance:textfield;
}

.flatpickr-current-month input.cur-year {
  padding-left: 3px;
}

.flatpickr-weekdays {
  margin-bottom: 7px;
}

.flatpickr-weekdaycontainer .flatpickr-weekday {
  font-size: 0.86rem;
}

.flatpickr-rContainer,
.flatpickr-days,
.dayContainer {
  min-width: 225px;
  max-width: 225px;
  width: 225px;
}

span.flatpickr-weekday {
  font-weight: 400;
  color: $steel-grey;
  width: calc(225px / 7);

  &:nth-child(6),
  &:nth-child(7) {
    color: $brick-red;
  }
}

.flatpickr-day {
  width: calc(225px / 7);
  flex-basis: calc(225px / 7 - 5px);
  -ms-flex-preferred-size: calc(225px / 7 - 5px);
  margin: 0 2px;
  height: 28px;
  font-size: 1rem;
  line-height: 27px;
  border-radius: 2px;

  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover {
    background-color: $brick-red;
    border-color: $brick-red;
    transition: all 0.15s ease;
    transition-property: background-color, border-color, color;
    box-shadow: none !important;
    color: #fff;
  }

  &:hover {
    background-color: $subtle-grey;
    border-color: $subtle-grey;
  }

  &.nextMonthDay:not(.flatpickr-disabled),
  &.prevMonthDay:not(.flatpickr-disabled) {
    color: #393939;
  }

  &:not(.flatpickr-disabled) {
    &:nth-child(7n-1),
    &:nth-child(7n) {
      color: $brick-red;

      &.selected {
        color: #fff;
      }
    }
    &.endRange , &.startRange {
      color: #fff;
    }
    @-moz-document url-prefix() {
      &:nth-child(7n-1),
      &:nth-child(7n) {
        color: unset;
      }
      &:nth-child(6n-1),
      &:nth-child(6n) {
        color: $brick-red;
      }
    }
  }

  &.nextMonthDay,
  &.prevMonthDay {
    color: $light-grey;
  }

  &.today {
    border-color: $brick-red;

    &:hover {
      border-color: $steel-grey;
      background-color: $steel-grey;
    }
  }
}
</style>
