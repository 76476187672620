export const state = () => ({
  isLoadingBtn: false,
  additionalData: {
    greenStatements: false,
    yellowStatements: false,
    redStatements: false,
  },
  history: [],
  agreements: [],
  contracts: [],
  companyData: {},
});

export const getters = {
  GET_BTN_LOADING: state => state.isLoadingBtn,
  GET_ADDITIONAL_DATA: state => state.additionalData,
  getHistory: state => state.history,
  getCompanyData: state => state.companyData,
};

export const actions = {
  GET_COMPANY_LABELS(_, id) {
    // return this.$axios.get(`/companies/labels`)
    //   .then(({ data }) => {
    //     console.log(data);
    // });
  },

  GET_PROFILE_DATA({ commit }, id) {
    return this.$axios.get(`/companies/${id}`)
      .then(({ data }) => {
        commit('setCompanyData', data);
        return data;
      });
  },

  GET_PROFILE_HISTORY({ commit }, id) {
    return this.$axios.get(`/companies/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);
        return data;
      }).catch(err => {
        console.log(err);
      });
  },

  GET_CUSTOMER_PROFILE_DATA({ commit }, { customerId, clientId }) {
    return this.$axios.get(`/customers/${customerId}/${clientId}`)
      .then(({ data }) => {
        commit('setCompanyData');
        return data;
      });
  },

  // GET_CUSTOMER_PROFILE_CONTRACTS({ commit }, { customerId, clientId }) {
  //   return this.$axios.get(`/customers/${customerId}/${clientId}/contracts`)
  //     .then(({ data }) => {
  //       commit('setCustomerContracts');
  //       return data;
  //     });
  // },
  //
  // GET_CUSTOMER_PROFILE_DOCUMENTS({ commit }, { customerId, clientId }) {
  //   return this.$axios.get(`/customers/${customerId}/${clientId}/documents`)
  //     .then(({ data }) => {
  //       commit('setCustomerDocuments');
  //       return data;
  //     });
  // },

  GET_PROFILE_DATA_BY_INN(_, inn) {
    return this.$axios.get('companies/details-for-creation', { params: { inn } })
      .then(({ data }) => data);
  },

  UPDATE_PROFILE_DATA({ commit }, payload) {
    commit('setBtnLoading', true);
    return this.$axios.put(`/companies/${payload.id}`, { ...payload })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  CREATE_COMPANY({ commit }, payload) {
    commit('setBtnLoading', true);
    return this.$axios.post('/companies', { ...payload })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  CREATE_CUSTOMER({ commit }, payload) {
    commit('setBtnLoading', true);
    return this.$axios.post('/customers/store', { ...payload })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  UPDATE_CUSTOMER({ commit }, payload) {
    commit('setBtnLoading', true);
    return this.$axios.post('/customers/update', { ...payload })
      .then(({ data }) => {
        commit('setBtnLoading', false);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },

  // DQBOX-472 скрыть тикер
  // CHECK_TICKER(_, { ticker, inn }) {
  //   return this.$axios.get('companies/check-ticker', {
  //     params: {
  //       ticker,
  //       inn,
  //     },
  //   });
  // },

  CHECK_INN(_, { inn, role = null }) {
    return this.$axios.get('companies/check-inn', {
      params: {
        inn,
        role,
      },
    });
  },

  LOAD_ADDITIONAL_DATA({ commit }, id) {
    commit('setBtnLoading', true);
    return this.$axios.get(`companies/additional-data/${id}`)
      .then(({ data }) => {
        commit('setBtnLoading', false);
        commit('setAdditionalData', data);
        return data;
      })
      .finally(() => {
        commit('setBtnLoading', false);
      });
  },
};

export const mutations = {
  setCompanyData(state, payload) {
    state.companyData = payload;
  },

  setBtnLoading(state, payload) {
    state.isLoadingBtn = payload;
  },

  setAgreements(state, payload) {
    state.agreements = payload;
  },

  setContracts(state, payload) {
    state.contracts = payload;
  },

  // setCustomerContracts(state, payload) {
  //   state.customerContracts = payload;
  // },
  //
  // setCustomerDocuments(state, payload) {
  //   state.customerDocuments = payload;
  // },

  setAdditionalData(state, [payload]) {
    let additionalData;
    const defaultAdditionslData = {
      greenStatements: false,
      yellowStatements: false,
      redStatements: false,
    };

    try {
      additionalData = payload.briefReport.summary;
    } catch (e) {
      additionalData = {};
    }

    Object.assign(defaultAdditionslData, additionalData);
    state.additionalData = defaultAdditionslData;
  },

  setHistory(state, payload) {
    state.history = payload;
  },

  updateLegalCheckCommentsCount(state, { type, value }) {
    state.companyData.company.legalCheckComments[type].count += value;
  },
};
