import moment from 'moment';
import { QUEUE_CHIP_STATUS_COLORS } from '@/assets/js/constants';
import { formatCarTrailerNumber } from '@/assets/js/utils';

const getStatusColor = (
  {
    unloading_date,
    unloading_time_from,
    unloading_time_to,
    arrival_datetime,
    called_at,
  }
) => {
  if (called_at) {
    return QUEUE_CHIP_STATUS_COLORS.CALLED;
  }

  const arrivalDatetime = moment.utc(arrival_datetime);

  const from = moment.utc(
    `${unloading_date} ${unloading_time_from}`,
    'YYYY-MM-DD HH:mm'
  );
  const to = moment.utc(
    `${unloading_date} ${unloading_time_to}`,
    'YYYY-MM-DD HH:mm'
  );

  if (arrivalDatetime.isBetween(from, to)) {
    return QUEUE_CHIP_STATUS_COLORS.ON_TIME;
  }

  if (arrivalDatetime.isBefore(from)) {
    return QUEUE_CHIP_STATUS_COLORS.EARLIER;
  }

  if (arrivalDatetime.isAfter(to)) {
    return QUEUE_CHIP_STATUS_COLORS.LATER;
  }
};

export const state = () => ({
  isLoading: false,
  queueData: [],
  isGroupedByPeriod: true,
  isGroupedByCargo: true,
});

export const getters = {
  isLoading: state => state.isLoading,
  queueData: state => state.queueData,
  isGroupedByPeriod: state => state.isGroupedByPeriod,
  isGroupedByCargo: state => state.isGroupedByCargo,
};

export const mutations = {
  setIsloading(state, payload) {
    state.isLoading = payload;
  },

  setQueueData(state, payload) {
    state.queueData = payload.map(trip => {
      return {
      ...trip,
      arrivalTime: moment(trip.arrival_time).format('HH:mm'),
      statusColor: getStatusColor(trip),
      isCalled: Boolean(trip.called_at),
      period: `${trip.unloading_time_from} - ${trip.unloading_time_to}`,
      unloadingDate: moment(trip.unloading_date).format('DD.MM'),
      carNumber: formatCarTrailerNumber(trip.car.number),
      quotaCompany: trip.quota.company?.name || this.$i18n.t('queue.Free quota'),
      productName: trip.quota.products && trip.quota.products.length && trip.quota.products.length > 0 ? trip.quota.products.map(({name}) => name).join(',') : '',
      unloadingFromDatetime: moment(`${trip.unloading_date} ${trip.unloading_time_from}`).format('YYYY-MM-DD HH:mm'),
    }});
  },

  setIsCalledDriver(state, carNumber) {
    state.queueData = state.queueData.map(trip => ({
      ...trip,
      isCalled: carNumber === trip.carNumber ? true : trip.isCalled,
      statusColor: getStatusColor({
        ...trip,
        called_at: carNumber === trip.carNumber ? moment() : trip.called_at,
      }),
    }));
  },

  setIsGroupedByCargo(state, payload) {
    state.isGroupedByCargo = payload;
  },

  setIsGroupedByPeriod(state, payload) {
    state.isGroupedByPeriod = payload;
  },
};

export const actions = {
  async getQueueData({ commit }) {
    commit('setIsloading', true);

    try {
      const { data: { data } } = await this.$axios.get('/queue');

      commit('setQueueData', data);
    } catch (error) {
      console.log(error);
    } finally {
      commit('setIsloading', false);
    }
  },

  async pushNotificationToDriver(_, payload) {
    await this.$axios.get(`/queue/${payload}/call`);
  },
};
