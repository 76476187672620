import { formatCarTrailerNumber, parsePreviewFileLinks } from '~/assets/js/utils';

export const state = () => ({
  quotaList: [],
  statusesList: [],
  quotaStatusId: 1,
  timePeriods: [],
  carsList: [],
  trailersList: [],
  driversList: [],
  settings: {},
  cargoStations: [],
  companyDirectories: [],
  timeSlotData: {},
  subQuotaData: {},
  isCreateFromQuotaTable: false,
  transporterDriversList: [],
  transporterCarsList: [],
  transporterTrailerList: [],
  subQuotaTableQuery: {},
  cargoParameters: [],
  tripDocuments: [],
  additionalInfo: {},
});

export const getters = {
  getQuotaList: state => state.quotaList,
  getStatusesList: state => state.statusesList,
  getQuotaStatusId: state => state.quotaStatusId,
  getTimePeriods: state => state.timePeriods,
  getCarsList: state => state.carsList,
  getTrailersList: state => state.trailersList,
  getDriversList: state => state.driversList,
  getSettings: state => state.settings,
  getCargoStations: state => state.cargoStations,
  getcompanyDirectories: state => state.companyDirectories,
  getTimeSlotData: state => state.timeSlotData,
  getSubQuotaData: state => state.subQuotaData,
  getSubQuotaTableQuery: state => state.subQuotaTableQuery,
  getIsCreateFromQuotaTable: state => state.isCreateFromQuotaTable,
  getTransporterDrivers: state => state.transporterDriversList,
  getTransporterCars: state => state.transporterCarsList,
  getTransporterTrailer: state => state.transporterTrailerList,
  getCargoParameters: state => [...state.cargoParameters].sort((a, b) => a.ordering - b.ordering),
  getTripDocuments: state => state.tripDocuments,
  getAdditionalInfo: state => state.additionalInfo,
};

export const mutations = {
  FETCH_QUOTA_SUCCESS(state, payload) {
    state.quotaList = payload;
  },

  FETCH_STATUSES_SUCCESS(state, payload) {
    state.statusesList = payload;
  },

  UPDATE_QUOTA_STATUS(state, payload) {
    state.quotaStatusId = payload;
  },

  FETCH_TIME_PERIODS_SUCCESS(state, payload) {
    state.timePeriods = payload;
  },

  FETCH_CARS_SUCCESS(state, payload) {
    state.carsList = payload;
  },

  FETCH_TRAILERS_SUCCESS(state, payload) {
    state.trailersList = payload;
  },

  FETCH_DRIVERS_SUCCESS(state, payload) {
    state.driversList = payload;
  },

  FETCH_SETTINGS_SUCCESS(state, payload) {
    state.settings = {
      ...payload,
      availablePeriods: payload.availablePeriods.length
        ? payload.availablePeriods
        : Object.values(payload.availablePeriods),
    };
  },

  setCargoStations(state, payload) {
    state.cargoStations = payload.map(station => ({ ...station, fromSettings: true }));
  },

  setCompanyDirectories(state, payload) {
    state.companyDirectories = payload;
  },

  setTimeSlotData(state, payload) {
    state.timeSlotData = payload;
  },
  setAdditionalInfo(state, payload) {
    state.additionalInfo = payload;
  },

  setSubQuotaData(state, payload) {
    state.subQuotaData = payload;
    state.isCreateFromQuotaTable = true;
  },
  setQuotasTableQuery(state, payload) {
    state.subQuotaTableQuery = payload;
  },

  refreshSubQuotaData(state) {
    state.subQuotaData = {};
    state.isCreateFromQuotaTable = false;
  },

  refreshQuotasTableQuery(state) {
    state.subQuotaTableQuery = {};
  },

  setTransporterDrivers(state, payload) {
    state.transporterDriversList = payload.map(driver => ({
      id: driver.id,
      name: driver.profile.fullName,
    }));
  },

  setTransporterCars(state, payload) {
    state.transporterCarsList = payload.map(car => ({
      ...car,
      name: formatCarTrailerNumber(car.number),
    }));
  },

  setTransporterTrailer(state, payload) {
    state.transporterTrailerList = payload.map(trailer => ({
      ...trailer,
      name: formatCarTrailerNumber(trailer.number),
    }));
  },

  setTimeSlotDocuments(state, { document_configs, documents }) {
    state.tripDocuments = document_configs.map(config => ({
      ...config,
      media: parsePreviewFileLinks(
        documents.filter(doc => doc.file.collection_name === config.alias),
      ),
    }));
  },

  refreshQuotaTimePeriods(state) {
    state.timePeriods = [];
  },

  setCargoParameters(state, payload) {
    state.cargoParameters = payload;
  },
};

export const actions = {
  async fetchQuotaList({ commit }, { tech_date, direction }) {
    await this.$axios.get('trips/quotas',
      { params: { tech_date, direction } })
      .then(({ data }) => {
        commit('FETCH_QUOTA_SUCCESS', data.quotas);
      })
      .catch(error => {
        console.log(error);
      });
  },

  createTimeslot({ commit }, data) {
    return this.$axios.post('trips', data)
      .then(response => true);
  },

  async loadTimeSlot({ commit }, id) {
    await this.$axios.get(`trips/${id}/edit`)
      .then(({ data }) => {
        commit('setTimeSlotData', data.timeSlot);
        commit('setCargoParameters', data.cargoParameters);
        commit('setTimeSlotDocuments', data);
        commit('setAdditionalInfo', data.additional_info);
      });
  },

  updateTimeSlot({ commit }, data) {
    return this.$axios.put(`trips/${data.id}`, data)
      .then(response => true);
  },

  async fetchTimeslotData({ commit }) {
    await this.$axios.get('trips/create')
      .then(({ data }) => {
        commit('setCargoStations', data.cargoStations);
        commit('setCompanyDirectories', data.companyDirectories);
        commit('FETCH_STATUSES_SUCCESS', data.statuses);
        commit('FETCH_SETTINGS_SUCCESS', data.settings);
      })
      .catch(error => {
        console.log(error);
      });
  },

  async fetchTimePeriods({ commit }, params) {
    await this.$axios.get('trips/periods', {
      params,
    })
      .then(({ data }) => {
        commit('FETCH_TIME_PERIODS_SUCCESS', data.periods);
      })
      .catch(error => {
        console.log(error);
      });
  },

  changeTimeslotStatus({ commit }, data) {
    return this.$axios.put(`trips/${data.id}/status`, data)
      .then(response => true);
  },

  async loadTransporterData({ commit }, id) {
    await this.$axios.get(`/trips/${id}/cars-drivers`)
      .then(({ data }) => {
        commit('setTransporterTrailer', data.trailers);
        commit('setTransporterCars', data.cars);
        commit('setTransporterDrivers', data.drivers);
      })
      .catch(err => {
        console.log(err);
      });
  },
};
