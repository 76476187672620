export const state = () => ({
  customerDocuments: [],
  customerContracts: [],
  documentLabels: [],
  contractsLabels: [],
  isOpenPopup: false,
});

export const getters = {
  GET_CUSTOMER_PROFILE_CONTRACTS: state => state.customerContracts,
  GET_CUSTOMER_PROFILE_DOCUMENTS: state => state.customerDocuments,
  GET_DOCUMENT_LABELS: state => state.documentLabels,
  GET_CONTRACT_LABELS: state => state.contractsLabels,
  GET_IS_OPEN_POPUP: state => state.isOpenPopup,
};

export const actions = {
  DELETE_DOCUMENT_ROW({ commit, state, dispatch }, payload) {
    const filesIds = state.customerDocuments[payload].documents.map(doc => doc.file.id);

    dispatch('media/removeMultipleFiles', filesIds, { root: true });

    commit('deleteDocumentRow', payload);
    commit('setOpenPopup', false);
  },

  async DELETE_CONTRACT_ROW({ commit, state, dispatch }, payload) {
    const filesIds = state.customerContracts[payload].documents.map(doc => doc.file.id);
    const response = await dispatch('media/removeMultipleFiles', filesIds, { root: true });

    if (!response) return Promise.resolve();

    commit('deleteContractRow', payload);
    commit('setOpenPopup', false);
    return Promise.resolve();
  },
};

export const mutations = {
  deleteContractRow(state, index) {
    state.customerContracts.splice(index, 1);
  },

  addContractRow(state, { name }) {
    state.customerContracts.push({
      name: name || '',
      number: '',
      date: '',
      documents: [],
    });
  },

  deleteDocumentRow(state, index) {
    state.customerDocuments.splice(index, 1);
  },

  setOpenPopup(state, payload) {
    state.isOpenPopup = payload;
  },

  addDocumentRow(state) {
    state.customerDocuments.push({
      name: '',
      comment: '',
      date: '',
      documents: [],
    });
  },

  changeField(state, { index, fieldName, value, isDocuments }) {
    state[isDocuments ? 'customerDocuments' : 'customerContracts'][index][fieldName] = value;
  },

  setCustomerContracts(state, payload) {
    state.customerContracts = (payload.agreements || []).map((v, i) => ({
      ...v,
      documents: (payload.media || []).filter(document => document.file.index === i + 1),
    }));
  },

  setCustomerDocuments(state, payload) {
    state.customerDocuments = (payload.contracts || []).map((v, i) => ({
      ...v,
      documents: (payload.media || []).filter(document => document.file.index === i + 1),
    }));
  },
};
