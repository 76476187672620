import { CancelToken, isCancel } from 'axios';
import quotaFilterModel from '~/components/Quotas/models/quotaFilterModel';

export const state = () => ({
  quotaList: [],
  products: [],
  availablePeriods: [],
  cargoStations: [],
  hasTurns: false,
  quotaData: {},
  unit: '',
  selectedDate: '',
  selectedDirection: '',
  isTableLoading: false,
  filters: quotaFilterModel(),
  commentList: [],
  actualComment: {},
  quotaFormInfo: {},
  quotaFormContragents: {},
  quotaFormProducts: [],
  quotaFormId: 0,
  quotaDataPrevDay: {},
});

export const getters = {
  getQuotaList: state => state.quotaList,
  getProducts: state => state.products,
  getAvailablePeriods: state => state.availablePeriods,
  getCargoStations: state => state.cargoStations,
  getHasTurns: state => state.hasTurns,
  getQuotaData: state => state.quotaData,
  getUnit: state => state.unit,
  getSelectedDate: state => state.selectedDate,
  getSelectedDirection: state => state.selectedDirection,
  isTableLoading: state => state.isTableLoading,
  getFilters: state => state.filters,
  getCommentList: state => state.commentList,
  getActualComment: state => state.actualComment,
  getQuotaFormInfo: state => state.quotaFormInfo,
  getQuotaFormContragents: state => state.quotaFormContragents,
  getQuotaFormProducts: state => state.quotaFormProducts,
  getQuotaFormId: state => state.quotaFormId,
  getQuotaDataPrevDay: state => state.quotaDataPrevDay,
};

export const mutations = {
  setQuotaList(state, payload) {
    state.quotaList = payload;
  },

  setProducts(state, payload) {
    state.products = payload;
  },

  setAvailablePeriods(state, payload) {
    if (Array.isArray(payload)) {
      state.availablePeriods = payload;
      state.hasTurns = false;
    } else {
      state.availablePeriods = Object.values(payload);
      state.hasTurns = true;
    }
  },

  setCargoStations(state, payload) {
    state.cargoStations = payload;
  },

  setQuotaData(state, payload) {
    state.quotaData = payload;
  },

  setQuotaFormInfo(state, payload) {
    state.quotaFormInfo = payload;
  },

  setUnit(state, payload) {
    state.unit = payload;
  },

  setSelectedDate(state, payload) {
    state.selectedDate = payload;
  },

  setSelectedDirection(state, payload) {
    state.selectedDirection = payload;
  },

  setTableLoading(state, payload) {
    state.isTableLoading = payload;
  },
  setFilters(state, payload) {
    state.filters = payload;
  },
  setCommentList(state, payload) {
    state.commentList = payload;
  },
  setActualComment(state, payload) {
    state.actualComment = payload;
  },
  setQuotaFormContragents(state, payload) {
    state.quotaFormContragents = payload;
  },
  setQuotaFormProducts(state, payload) {
    if (Array.isArray(payload)) {
      state.quotaFormProducts = payload;
    } else {
      state.quotaFormProducts = [payload];
    }
  },

  setQuotaFormId(state, payload) {
    state.quotaFormId = payload;
  },

  setQuotaDataPrevDay(state, payload) {
    state.quotaDataPrevDay = payload;
  },
};

export const actions = {
  async loadQoutaList({ state, commit }, payload) {
    const params = payload || state.filters;

    if (this.cancelSource) {
      this.cancelSource.cancel(
        'Request cancelled'
      );
    }
    this.cancelSource = CancelToken.source();

    commit('setTableLoading', true);

    await this.$axios.get('/quotas', {
      params: {
        tech_date: params.tech_date,
        direction: params.direction,
      },
      cancelToken: this.cancelSource && this.cancelSource.token,
    })
      .then(({ data }) => {
        commit('setQuotaList', data.data);
      })
      .catch(error => {
        if (isCancel(error)) {
          console.log('Request canceled');
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async loadPublicQuotaList({ state, commit }, payload) {
    const params = payload || state.filters;

    if (this.cancelSource) {
      this.cancelSource.cancel(
        'Request cancelled'
      );
    }

    commit('setTableLoading', true);

    await this.$axios.get('/guest-quotas', {
      params: {
        tech_date: params.tech_date,
        direction: 2,
      },
    })
      .then(({ data }) => {
        commit('setQuotaList', data.data);
      })
      .catch(error => {
        if (isCancel(error)) {
          console.log('Request canceled');
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        commit('setTableLoading', false);
      });
  },

  async loadComments({ commit }, params) {
    await this.$axios.get(`product-types/${params}/comments`)
      .then(({ data }) => {
        commit('setCommentList', data.comments);
      }).catch(error => console.error(error));
  },

  async createComment({ commit, dispatch }, data) {
    const response = await this.$axios.post(`product-types/${data.id}/comment`, { comment: data.comment });
    dispatch('loadComments', data.id);
    commit('setActualComment', response.data.comment);
  },

  async loadLabels({ commit }, route) {
    await this.$axios.get(route)
      .then(({ data }) => {
        commit('setProducts', data.products);
        commit('setAvailablePeriods', data.availablePeriods);
        commit('setUnit', data.unit);
      })
      .catch(error => console.log(error));
  },

  async loadCargoStations({ commit }, params) {
    await this.$axios.get('/cargo-stations', {params})
      .then(({ data }) => {
        commit('setCargoStations', data);
      });
  },

  async loadQuota({ commit }, id) {
    await this.$axios.get(`quotas/${id}/edit`)
      .then(({ data }) => {
        commit('setQuotaData', data.quota);
        return data;
      })
      .catch(error => console.log(error));
  },

  async loadQuotaFormInfo({ commit }, id) {
    await this.$axios.get(`quotas/${id}/info`)
      .then(({ data }) => {
        commit('setQuotaFormContragents', data.contragents);
        commit('setQuotaFormProducts', data.product_batches);
        commit('setQuotaFormId', data.quota_id);
      })
      .catch(error => console.log(error));
  },

  async updateQuotaForm({ state, getters, commit }, payload) {
    return this.$axios.put(`quotas/${getters.getQuotaFormId}/update-info`, payload)
      .then(() => {
        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  },

  async loadQuotaDataPrevDate({ commit }, payload) {
    await this.$axios.get(`quotas/prev-info`, {params: {...payload}})
      .then(({ data }) => {
        if (!Array.isArray(data)) {
          commit('setQuotaFormContragents', data.contragents);
          commit('setQuotaFormProducts', data.product_batches);
        }
      })
      .catch(error => console.log(error));
  },

  createQouta({ dispatch }, data) {
    return this.$axios.post('/quotas', data)
      .then(() => {
        dispatch('loadQoutaList');
        return true;
      });
  },

  updateQouta({ dispatch }, data) {
    return this.$axios.put(`/quotas/${data.id}`, data)
      .then(() => {
        dispatch('loadQoutaList');
        return true;
      });
  },

  deleteQouta({ dispatch }, id) {
    return this.$axios.delete(`/quotas/${id}`)
      .then(() => {
        dispatch('loadQoutaList');
        return true;
      })
      .catch(err => {
        console.log(err);
        return false;
      });
  },
};
