<template>
  <div>
    <transition name="header-menu">
      <div class="pb-2 sidepanel-header-menu">
        <!-- <ul class="sidepanel-header-menu__list">
          <template
            v-for="option in menuOptions"
          >
            <nuxt-link
              :key="option.vueKey"
              v-slot="{ isExactActive, navigate, href }"
              :to="option.link"
            >
              <li
                class="px-4 sidepanel-header-menu__item"
                :class="{'sidepanel-header-menu__item--active': isExactActive}"
              >
                <a
                  :href="href"
                  class="sidepanel-header-menu__link"
                  @click="navigate"
                >
                  <TIconSvg
                    :name="option.icon"
                    :view-box="option.viewBox"
                    width="19"
                    height="19"
                    fill="none"
                    stroke="none"
                    class="mr-3 sidepanel-header-menu__icon"
                  />
                  <span class="sidepanel-header-menu__text">
                    {{ option.label }}
                  </span>
                </a>
              </li>
            </nuxt-link>
          </template>
        </ul> -->
        <div class="sidepanel-header-menu__separator" />
        <TFlex>
          <TButton
            color="white"
            transparent
            :icon="`locales/${$i18n.locale}`"
            icon-width="19"
            icon-height="13"
            icon-fill="none"
            icon-stroke="none"
            icon-view-box="0 0 19 13"
            class="sidepanel-header-menu__button"
            @click.stop="openLocalePopup"
          >
            {{currentLocaleName}}
          </TButton>
          <TButton
            color="white"
            transparent
            icon="exit"
            icon-width="19"
            icon-height="19"
            icon-fill="none"
            icon-stroke="none"
            icon-view-box="0 0 17 15"
            class="sidepanel-header-menu__button"
            @click="logout"
          >
            Выход
          </TButton>
        </TFlex>
      </div>
    </transition>
    <SidepanelMenuLocalePopup
      v-if="isLocalePopupShown"
      @accept="switchLocale"
      @cancel="closeLocalePopup"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

// Constants
import { I18N_COOKIE } from '~/assets/js/constants';

// Components
import SidepanelMenuLocalePopup from './SidepanelMenu/components/SidepanelMenuLocalePopup';

const RELOAD_TRIGGER = 'reloadTrigger';

export default {
  name: 'SidepanelHeaderMenu',

  components: {
    SidepanelMenuLocalePopup,
  },

  data: () => ({
    isLocalePopupShown: false,
  }),

  computed: {
    ...mapGetters({
      isLogoutBtnLoading: 'auth/login/GET_LOGOUT_CONTROL_LOADING',
    }),

    currentLocaleName() {
      return this.$i18n.locales.find(locale => locale.code === this.$i18n.locale).name;
    },
  },

  methods: {
    ...mapActions({
      logOut: 'auth/login/LOG_OUT',
      removeAuthCookie: 'general/REMOVE_AUTH_COOKIE',
      removeAuthHeader: 'general/REMOVE_AUTH_HEADER',
      saveLocale: 'main/saveLocale',
    }),

    logout() {
      this.logOut()
        .then(() => {
          this.removeAuthCookie();
          this.removeAuthHeader();
          this.$router.push('/login');
          localStorage.setItem(RELOAD_TRIGGER, new Date().valueOf());
        });
    },

    openLocalePopup() {
      this.isLocalePopupShown = true;
    },

    switchLocale(newLocaleCode) {
      this.$cookies.set(I18N_COOKIE, newLocaleCode);
      this.$axios.setHeader('Accept-Language', newLocaleCode);
      this.saveLocale(newLocaleCode);
      this.closeLocalePopup();
      this.$router.go();
    },

    closeLocalePopup() {
      this.isLocalePopupShown = false;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.sidepanel-header-menu {
  $self: &;

  position: absolute;
  top: 62px;
  left: 9px;
  z-index: 1;
  padding: 0 17px;
  padding-top: 8px;
  width: 238px;
  background-color: #39464d;
  box-shadow: 4px 6px 10px rgba(25, 38, 45, 0.5);

  &__separator {
    border-top: 1px solid rgba(212, 217, 220, 0.5);
    width: 100%;
  }

   &__list {
     list-style-type: none;
     margin: 0;
     padding-left: 0;
   }

   &__item {
     @include centerFlexY;

     &:nth-child(2) {
       #{$self}__icon {
         position: relative;
         left: -2px;
       }
     }

     &:last-child {
       #{$self}__link {
         &::after {
           content: '';
           display: block;
           position: absolute;
           bottom: -2px;
           width: 100%;
           height: 1px;
           background-color: #fff;
         }
       }
     }

     &--active {
       background-color: $almost-black;
     }
   }

   &__link {
     position: relative;
     width: 100%;
     padding: 6px 0;
     @include centerFlexY;
   }

  &__button {
    margin-top: 6px;
    width: 100%;
    border-radius: 0;
  }

  &__error {
    white-space: normal;
    background-color: rgba(#fff, 0.4);
  }

  .t-button {
    justify-content: flex-start;
    font-weight: 400;
    transition: opacity 0.3s;

    &__icon {
      margin-right: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }
}

.header-menu-enter-active,
.header-menu-leave-active {
  transition: opacity 0.3s;
}

.header-menu-enter,
.header-menu-leave-to {
  opacity: 0;
}
</style>
