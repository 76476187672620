<template>
  <Component
    :is="isButton ? 'button' : 'nuxt-link'"
    class="t-button"
    :type="isButton ? type : undefined"
    :class="{
      [`t-button--${colorText}`]: colorText,
      't-button--without-text': hasText($slots.default),
      't-button--disabled': disabled,
      't-button--transparent': transparent,
      't-button--loading': loading,
      't-button--link': !isButton,
      't-button--secondary-action': secondaryAction,
      't-button--bold': isBold,
      't-button--outlined': outlined,
    }"
    :target="target || undefined"
    :to="href || undefined"
    :disabled="(isButton ? disabled : undefined) || loading"
    @click="$emit('click', $event)"
  >
    <Transition name="fade">
      <TPreloader
        v-if="loading"
        :color="isDefaultColorLoading ? '' : preloaderColor"
        size="20"
      />
    </Transition>
    <TIconSvg
      v-if="icon !== ''"
      class="t-button__icon"
      :name="icon"
      :width="iconWidth"
      :height="iconHeight"
      :view-box="iconViewBox"
      :fill="iconFill"
      :stroke="iconStroke"
      :class="{
        't-button__icon--without-text': hasText($slots.default),
        't-button__icon--loading': loading,
      }"
    />
    <slot />
    <slot name="after" />
  </Component>
</template>

<script>
export default {
  name: 'TButton',

  props: {
    color: {
      type: String,
      default: 'red',
    },

    isDefaultColorLoading: {
      type: Boolean,
      default: false,
    },

    href: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
    },

    target: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'button',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    secondaryAction: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    transparent: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    iconWidth: {
      type: [Number, String],
      default: 17,
    },

    iconHeight: {
      type: [Number, String],
      default: 17,
    },

    iconViewBox: {
      type: String,
      default: '0 0 17 17',
    },

    iconFill: {
      type: String,
      default: 'currentColor',
    },

    iconStroke: {
      type: String,
      default: 'currentColor',
    },

    isBold: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    isButton() {
      return this.href === '';
    },

    colorText() {
      switch (this.color) {
        case 'red':
          return 'red';
        case 'blue':
          return 'blue';
        case 'white':
          return 'white';
        case 'black':
          return 'black';
        case 'yellow':
          return 'yellow';
        case 'grey':
          return 'grey';
        default:
          return '';
      }
    },

    preloaderColor() {
      if (this.transparent) { return ''; }
      if (this.transparent) {
        return '';
      }

      switch (this.color) {
        case 'red':
          return 'white';
        default:
          return 'white';
      }
    },
  },

  methods: {
    hasText(slot) {
      return slot === undefined || slot[0].text?.trim() === '';
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

@mixin pseudoClasses {
  &:hover:not([disabled]) {
    @content;
  }
}

$colors: (
  'red': $brick-red,
  'blue': $light-blue,
  'white': #fff,
  'black': $almost-black,
  'yellow': $golden-yellow,
  'grey': $light-grey,
);

.t-button {
  $self: &;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $default-control-height;
  padding: 0 20px;
  border-radius: 3px;
  color: #fff;
  outline: none;
  transition: all 0.15s ease;
  transition-property: background-color, color;
  cursor: pointer;

  &--bold {
    font-weight: 600;
  }

  @each $btn-name, $btn-color in $colors {
    &--#{$btn-name} {
      background-color: $btn-color;

      @include pseudoClasses {
        background-color: darken($btn-color, 10%);
      }

      &:active:not([disabled]){
        background-color: rgba($btn-color, 0.8);
      }

      &#{$self}--transparent {
        color: $btn-color;

        @include pseudoClasses {
          color: darken($btn-color, 10%);
        }

        &:active:not([disabled]){
          color: rgba($btn-color, 0.8);
        }
      }

      &#{$self}--link {
        color: $btn-color;

        &::before {
          border-top: 1px dashed $btn-color;
        }

        @include pseudoClasses {
          color: darken($btn-color, 10%);;

          &::before {
            border-top: 1px dashed darken($btn-color, 10%);;
          }
        }

        &:active:not([disabled]){
          color: rgba($btn-color, 0.8);
        }

        &::before {
          border-top: 1px dashed rgba($btn-color, 0.8);;
        }
      }
    }
  }

    &--yellow {
      color: $almost-black;
    }

    &--transparent,
    &--link {
      padding-left: 0;
      padding-right: 0;
      background: none;

      @include pseudoClasses {
        background: none;
      }

      &--disabled {
        opacity: 0.6;
      }
    }

    &--link {
      position: relative;

      &--disabled {
        &::before {
          border-color: rgba($steel-grey, 0.4);
          opacity: 0.6;
        }
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
        height: 0;
      }
    }

  &--disabled {
    opacity: 0.6;
    cursor: default;
  }

  &--secondary-action {
    color: $steel-grey;
    border: 1px solid $light-grey;
    background-color: #fff;

    &:hover:not([disabled]) {
      background-color: $light-grey;
    }
  }

  &--outlined {
    color: #000;
    border: 1px solid $light-brown-grey;
    background-color: #fff;

    &:hover:not([disabled]) {
      background-color: $white-light-grey;
    }
  }

  &--without-text {
    justify-content: center;
    padding: 0;
    @include squareBox($default-control-height);
  }

  &--loading {
    color: rgba(#fff, 0);
  }

  &__icon {
    max-height: 20px;
    margin-right: 6px;
    transition: opacity 0.3s ease;

    &--without-text {
      margin-right: 0;
      max-width: 20px;
    }

    &--loading {
      opacity: 0;
    }

    #{$self}--transparent#{$self}--disabled &,
    #{$self}--link#{$self}--disabled & {
      opacity: 0.6;
    }
  }

  .t-preloader {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}
</style>
