import Vue from 'vue';

Vue.filter(
  'phoneNumber',
  phone => {
    if (!phone) {
      return '';
    }

    if (phone.length >= 11 && phone.length <= 13) {
      return phone.replace(/(.+)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
    }

    if (phone.length > 13 && phone.length <= 15) {
      return phone.replace(/(.+)(\d{3})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4-$5-$6');
    }

    return phone;
  }
);

Vue.filter(
  'passportNumber',
  n => {
    if (n === null) {
      return '';
    }

    if (Number.isNaN(Number(n))) {
      return '';
    }

    const [, m1, m2] = n.match(/^(\d{4})(\d{6})$/);

    return `${m1} ${m2}`;
  }
);

Vue.filter(
  'carLicenseNumber',
  n => {
    if (n === null) {
      return '';
    }

    return n.match(/[\S\s]{1,6}/g).join(' ');
  }
);
