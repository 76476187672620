<template>
  <div
    ref="sidepanel-header"
    class="sidepanel-header"
    :class="{'sidepanel-header--closed': isShrunk}"
    @click="toggleMenu"
  >
    <div
      v-show="!isShrunk"
      class="sidepanel-header__info-container"
      :class="{
        'sidepanel-header__info-container--open': isMenuShown
      }"
    >
      <div class="sidepanel-header__name">
        <div
          ref="nameContainerRef"
          class="sidepanel-header__name-text"
          :class="{'sidepanel-header__name-text--double-surname': hasDoubleSurname}"
        >
          {{nameText}}
        </div>
        <span>
          <TIconSvg
            class="mx-1 mt-1 sidepanel-header__arrow"
            :class="{'sidepanel-header__arrow--up': isMenuShown}"
            name="arrow-down"
            view-box="0 0 512 512"
            height="10"
            width="15"
          />
        </span>
      </div>
      <div class="sidepanel-header__position">
        {{position}} {{company}}
      </div>
    </div>
    <div>
      <SidepanelHeaderMenu
        v-show="isMenuShown"
        class="sidepanel-header__menu"
      />
    </div>
  </div>
</template>

<script>
import SidepanelHeaderMenu from './SidepanelHeaderMenu';

const WIDTH_AVAILABLE_FOR_NAME = 180;

export default {
  name: 'SidepanelHeader',

  components: {
    SidepanelHeaderMenu,
  },

  props: {
    name: {
      type: String,
      default: 'Имя отсутствует',
    },

    shortName: {
      type: String,
      default: '',
    },

    hasDoubleSurname: {
      type: Boolean,
      default: false,
    },

    position: {
      type: String,
      default: '',
    },

    company: {
      type: String,
      default: '',
    },

    isShrunk: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isMenuShown: false,
    widthNeededForName: 0,
  }),

  computed: {
    nameText() {
      return this.widthNeededForName <= WIDTH_AVAILABLE_FOR_NAME
        ? this.name
        : this.shortName;
    },
  },

  watch: {
    isShrunk(curr, last) {
      if (curr && !last) {
        this.isMenuShown = false;
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.toggleDropdown);

    this.widthNeededForName = this.$refs.nameContainerRef.scrollWidth;
  },

  beforeDestroy() {
    document.removeEventListener('click', this.toggleDropdown);
  },

  methods: {
    toggleMenu() {
      if (!this.isShrunk) {
        this.isMenuShown = !this.isMenuShown;
      }
    },

    toggleDropdown({ target }) {
      const checkOf = element => element || '';
      const header = checkOf(this.$refs['sidepanel-header']);
      const hasTarget = element => element !== '' && element.contains(target);

      if (!hasTarget(header)) {
        this.isMenuShown = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.sidepanel-header {
  margin: 10px 9px 0;
  line-height: 20px;
  white-space: nowrap;
  transition: background-color 300ms;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  user-select: none;
  cursor: pointer;

  &--closed {
    margin-bottom: 16px;
    min-height: 40px;
  }

  &__info-container {
    padding: 0 17px;
    padding-top: 8px;
    padding-bottom: 8px;

    &--open {
      transition: background-color 300ms;
      background-color: $light-almost-black;
      box-shadow: 4px 4px 10px rgba(25, 38, 45, 0.5);
    }
  }

  &__position {
    color: $light-brown-grey;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name {
    display: flex;
  }
  &__name-text {
    text-overflow: ellipsis;
    overflow: hidden;

    &--double-surname {
      white-space: initial;
      word-break: break-word;
      text-overflow: unset;
    }
  }

  &__arrow {
    position: relative;
    cursor: pointer;
    transform: rotate(0);
    transition: transform 0.3s;

    &--up {
      transform: rotate(180deg);
    }
  }
}
</style>
