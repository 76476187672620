<template>
  <div
    class="sidepanel"
    :class="{ 'sidepanel--shrunk': isShrunk }"
  >
    <div class="sidepanel__wrapper">
      <div
        class="sidepanel__toggle"
        @click="shrunkMenu"
      >
        <TIconSvg
          class="sidepanel__toggle-icon"
          :class="{
            'sidepanel__toggle-icon--shrunk': isShrunk,
          }"
          name="menu/arrow"
          width="7"
          height="12"
          view-box="0 0 7 12"
        />
      </div>
      <div
        v-bar
        class="sidepanel__scroll-wrapper"
        :class="{
          'sidepanel__scroll-wrapper--shrunk': isShrunk,
        }"
      >
        <div class="sidepanel__inner">
          <SidepanelHeader
            :name="userName"
            :short-name="shortUserName"
            :has-double-surname="hasDoubleSurname"
            :position="currentUser && currentUser.roles && currentUser.roles[0] ?
              currentUser.roles[0].label : ''"
            :company="userCompany"
            :is-shrunk="isShrunk"
          />
          <SidepanelMenu
            class="sidepanel__menu"
            :items="menu"
            :is-shrunk="isShrunk"
          />
        </div>
      </div>
<!--      <div-->
<!--        v-show="!isShrunk"-->
<!--        class="sidepanel__help"-->
<!--      >-->
<!--        {{$t('auth.If you have any questions, please contact support')}}-->
<!--        <br><a href="mailto: help@alltrucks.online">help@alltrucks.online</a>-->
<!--        <TLink-->
<!--          :is-nuxt-link="false"-->
<!--          :is-underlined="false"-->
<!--          route="tel:88005507087"-->
<!--        >-->
<!--          8(800)550-70-87-->
<!--        </TLink>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
// utils
import { formatPhone } from '~/assets/js/utils';
// mixins
import accessMixin from '~/assets/js/mixins/accessMixin';
// Components
import SidepanelHeader from './components/SidepanelHeader';
import SidepanelMenu from './components/SidepanelMenu';

export default {
  name: 'Sidepanel',

  components: {
    SidepanelHeader,
    SidepanelMenu,
  },

  mixins: [accessMixin],

  props: {
    isShownSidebar: {
      type: Boolean,
      default: true,
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {

    menu() {
      return [
        {
          text: this.$t('sidepanel.Main'),
          icon: 'menu/home',
          link: '/',
          iconWidth: 25,
          iconHeight: 25,
          isShow: true,
        },
        {
          text: this.$t('sidepanel.Quotas'),
          icon: 'menu/quota',
          link: '/quotas',
          iconViewBox: '0 0 25 25',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewQuotas,
        },
        {
          text: this.$t('sidepanel.Trips'),
          icon: 'menu/time-slots',
          link: '/timeslots',
          iconViewBox: '0 0 25 25',
          iconWidth: 25,
          iconHeight: 25,
          iconStroke: 'currentColor',
          isShow: this.isViewTimeslots,
        },
        // trips here
        {
          text: this.$t('sidepanel.Unloading queue'),
          icon: 'menu/queue',
          link: '/queue',
          iconViewBox: '0 0 25 17',
          iconWidth: 25,
          iconHeight: 25,
          iconStroke: 'currentColor',
          isShow: this.isViewQueue,
        },
        {
          text: this.$t('sidepanel.Contractors'),
          icon: 'menu/companies',
          link: '/companies',
          iconViewBox: '0 0 25 17',
          iconWidth: 25,
          iconHeight: 17,
          isShow: this.isViewCompanies,
        },
        {
          text: this.$t('sidepanel.Transport'),
          icon: 'menu/transport',
          link: '/transport',
          iconViewBox: '0 0 27 24',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewCars,
        },
        {
          text: this.$t('sidepanel.Drivers'),
          icon: 'menu/drivers',
          link: '/drivers',
          iconViewBox: '0 0 25 25',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewDrivers,
        },
        {
          text: this.$t('sidepanel.Staff'),
          icon: 'menu/users',
          link: '/users',
          iconViewBox: '0 0 25 17',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewUser,
        },
        {
          text: this.$t('sidepanel.Company Profile'),
          icon: 'menu/profile',
          link: '/profile',
          iconStroke: 'currentColor',
          iconViewBox: '-4 -1 19 24',
          iconWidth: 22,
          iconHeight: 27,
          isShow: this.isViewProfile,
        },
        {
          text: this.$t('sidepanel.Settings'),
          icon: 'menu/settings',
          link: '/settings',
          iconStroke: 'currentColor',
          iconViewBox: '0 0 25 25',
          iconWidth: 25,
          iconHeight: 25,
          isShow: this.isViewSettings,
        },
      ].filter(v => v.isShow);
    },

    userName() {
      return this.currentUser
        && this.currentUser.profile
        && (this.currentUser.profile.fullName.trim()
          ? this.currentUser.profile.fullName
          : this.formatPhone(this.currentUser.phone));
    },

    shortUserName() {
      return this.currentUser
        && this.currentUser.profile
        && this.currentUser.profile.shortName;
    },

    hasDoubleSurname() {
      return this.currentUser
        && this.currentUser.profile
        && this.currentUser.profile.surname
        && this.currentUser.profile.surname.includes('-');
    },

    userCompany() {
      if (this.isAdmin) {
        return '';
      }
      return this.currentUser
        && this.currentUser.company
        && (this.currentUser.company.name
          ? this.currentUser.company.name
          : `ИНН ${this.currentUser.company.inn}`);
    },

    isShrunk() {
      return this.$store.getters['general/IS_MENU_SHRUNK'];
    },
  },

  methods: {
    formatPhone,

    shrunkMenu() {
      this.$cookies.set('isMenuOpened', !this.isShrunk);
      this.$store.dispatch('general/TOGGLE_MENU_SHRUNK', !this.isShrunk);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

$full-sidepanel-width: 256px;
$shrunk-sidepanel-width: 56px;

.sidepanel {
  $self: &;

  color: #FFF;
  height: 100vh;
  width: $full-sidepanel-width;
  min-width: $full-sidepanel-width;
  position: relative;
  transition: width 0.2s;

  &__help {
    position: absolute;
    bottom: 26px;
    left: 28px;

    a {
      color: $light-blue;
      &:hover {
        color: darken($light-blue, 5%);
      }
    }
  }

  &__toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -12px;
    z-index: 232;
    top: 52px;
    background-color: $golden-yellow;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.15s;
  }

  &:hover {
    #{$self}__toggle {
      opacity: 1;
    }
  }

  &--shrunk {
    width: $shrunk-sidepanel-width;
    min-width: $shrunk-sidepanel-width;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 115;
  }

  &__inner {
    display: flex !important; // For scroll plugin, which adds display: block
    flex-direction: column;
    align-items: stretch;
  }

  &__scroll-wrapper {
    height: 100vh;
    width: $full-sidepanel-width;
    padding: 0 0 120px 0;
    overflow-y: auto;
    transition: width 0.2s;
    background-color: $almost-black;

    &--shrunk {
      width: $shrunk-sidepanel-width;
    }

    &--hidden {
      width: 0;
    }
  }

  &__inner {
    display: flex !important; // For scroll plugin, which adds display: block
    flex-direction: column;
    align-items: stretch;
    position: relative;
  }

  &__toggle-icon {
    transform: rotate(180deg);
    margin-right: 1px;

    &--shrunk {
      transition: width 0.2s;
      transform: rotate(0deg);
      margin-right: 0;
    }
  }
}
</style>
