// eslint-disable-next-line import/no-extraneous-dependencies
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState('main', {
      currentUser: store => store.currentUser,
      menuSections: store => store.menuSections,
      permissions: store => store.permissions,
      access: store => store.access,
      currentAccess: store => store.currentAccess,
    }),

    ...mapGetters('main', [
      'isAdmin',
      'isClient',
      'isTransporter',
      'isCompanyEmployee',
      'getUserCompany',
    ]),

    ...mapGetters('main/user-permissions', [
      'isViewUser',
      'isEditUser',
      'isViewCars',
      'isEditCars',
      'isViewDrivers',
      'isEditDrivers',
      'isViewTransporter',
      'isEditTransporter',
      'isViewProfile',
      'isEditProfile',
      'isViewCompanies',
      'isEditCompanies',
      'isViewSettings',
      'isEditSettings',
      'isViewQuotas',
      'isEditQuotas',
      'isViewTimeslots',
      'isEditTimeslots',
      'isEditQueue',
      'isViewQueue',
    ]),
  },

  methods: {
    forrmattedPermissions(userPermission) {
      return this.permissions.reduce((acc, { id, section, action }) => {
        const currentPerm = userPermission.find(({ id: currentId }) => currentId === id);
        if (!currentPerm) return acc;
        if (acc[section]) {
          // eslint-disable-next-line no-param-reassign
          acc[section][action] = {
            isCheck: true,
            id: currentPerm.id,
          };
        } else {
          // eslint-disable-next-line no-param-reassign
          acc[section] = {};
          // eslint-disable-next-line no-param-reassign
          acc[section][action] = {
            isCheck: true,
            id: currentPerm.id,
          };
        }
        return acc;
      }, {});
    },
  },
};
