<template>
  <div class="t-popup">
    <div
      v-if="hasOverlay"
      class="t-popup__overlay"
      @click="overlayClose ? $emit('close') : ''"
    />
    <div
      class="t-popup__wrapper"
    >
      <div
        :style="{ width }"
        class="t-popup__content"
      >
        <button
          v-if="hasCloseButton"
          type="button"
          class="t-popup__close-button"
          @click="$emit('close')"
        >
          <TIconSvg
            name="close"
            fill="#C4C4C4"
            stroke="none"
            height="14"
            width="14"
            view-box="0 0 11 11"
          />
        </button>
        <div class="t-popup__text">
          <slot />
        </div>
        <div class="t-popup__bottom">
          <slot name="bottom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TPopup',

  props: {
    hasOverlay: {
      type: Boolean,
      default: true,
    },

    overlayClose: {
      type: Boolean,
      default: false,
    },

    hasCloseButton: {
      type: Boolean,
      default: true,
    },

    width: {
      type: String,
      default: '495px',
    },

    lockBackground: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    if (this.lockBackground) {
      document.body.style.overflow = 'hidden';
    }
  },

  beforeDestroy() {
    if (this.lockBackground) {
      document.body.style.overflow = 'visible';
    }
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
@import 'assets/scss/_mixins';

.t-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include fullScreen;
  overflow: hidden;
  z-index: 400;

  &__overlay {
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }

  &__wrapper {
    @include centerAbsoluteXY;
  }

  &__bottom {
    margin-top: 16px;
  }

  &__content {
    padding-top: 35px;
    background-color: #FFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.24);
  }

  & &__close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  @media (min-width: $media-xs) {
    padding: 0;
    overflow: hidden;

    &__content {
      padding: 40px 44px 32px;
    }
  }
}
</style>
