var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.isButton ? 'button' : 'nuxt-link',{tag:"Component",staticClass:"t-button",class:{
    [`t-button--${_vm.colorText}`]: _vm.colorText,
    't-button--without-text': _vm.hasText(_vm.$slots.default),
    't-button--disabled': _vm.disabled,
    't-button--transparent': _vm.transparent,
    't-button--loading': _vm.loading,
    't-button--link': !_vm.isButton,
    't-button--secondary-action': _vm.secondaryAction,
    't-button--bold': _vm.isBold,
    't-button--outlined': _vm.outlined,
  },attrs:{"type":_vm.isButton ? _vm.type : undefined,"target":_vm.target || undefined,"to":_vm.href || undefined,"disabled":(_vm.isButton ? _vm.disabled : undefined) || _vm.loading},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('Transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('TPreloader',{attrs:{"color":_vm.isDefaultColorLoading ? '' : _vm.preloaderColor,"size":"20"}}):_vm._e()],1),_vm._v(" "),(_vm.icon !== '')?_c('TIconSvg',{staticClass:"t-button__icon",class:{
      't-button__icon--without-text': _vm.hasText(_vm.$slots.default),
      't-button__icon--loading': _vm.loading,
    },attrs:{"name":_vm.icon,"width":_vm.iconWidth,"height":_vm.iconHeight,"view-box":_vm.iconViewBox,"fill":_vm.iconFill,"stroke":_vm.iconStroke}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("after")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }