<template>
  <div class="t-tabs">
    <div class="t-tabs__header">
      <button
        v-for="(tab, tabIndex) in tabs"
        :key="tabIndex"
        :style="{ padding: buttonPadding }"
        :class="{ 't-tabs__button--active': tab.isActive }"
        class="t-tabs__button"
        @click="selectTab(tabIndex)"
      >
        {{tab.label}}
        <span
          v-if="tab.numbersCount"
          class="ml-1 numbers-indicator colored-status colored-status--number yellow"
        >
          {{tab.numbersCount}}
        </span>
        <span
          v-if="tab.approvedDocsCount"
          class="ml-1 numbers-indicator colored-status colored-status--number green"
        >
          {{tab.approvedDocsCount}}
        </span>
        <span
          v-if="tab.approvedCarsCount"
          class="ml-1 numbers-indicator colored-status colored-status--number green"
        >
          {{tab.approvedCarsCount}}
        </span>
        <span
          v-if="tab.approvedDriversCount"
          class="ml-1 numbers-indicator colored-status colored-status--number green"
        >
          {{tab.approvedDriversCount}}
        </span>
        <span
          v-if="tab.canceledCarsCount"
          class="ml-1 numbers-indicator colored-status colored-status--number red"
        >
          {{tab.canceledCarsCount}}
        </span>
        <span
          v-if="tab.canceledDriversCount"
          class="ml-1 numbers-indicator colored-status colored-status--number red"
        >
          {{tab.canceledDriversCount}}
        </span>
        <span
          v-if="tab.canceledDocsCount"
          class="ml-1 numbers-indicator colored-status colored-status--number red"
        >
          {{tab.canceledDocsCount}}
        </span>
        <DocumentComments
          v-if="tab.driversCommentsUnread"
          only-icon
          :has-new-comment="newComment"
        />
        <DocumentComments
          v-if="tab.carsCommentsUnread"
          only-icon
          :has-new-comment="newComment"
        />
      </button>
    </div>
    <div class="t-tabs__content">
      <slot />
    </div>
  </div>
</template>

<script>
// Components
import DocumentComments from '~/components/Features/DocumentComments';

export default {
  name: 'TTabs',

  components: {
    DocumentComments,
  },

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    buttonPadding: {
      type: String,
      default: '5px 12px',
    },
  },

  data: () => ({
    tabs: [],
    newComment: true,
  }),

  watch: {
    value(activeTab, oldValue) {
      const tabIndex = this.tabs
        .findIndex(tab => {
          if (typeof tab.slug === 'string') {
            return tab.slug.toLowerCase() === activeTab.toLowerCase();
          }

          return tab.slug === activeTab;
        });

      if (tabIndex !== -1) {
        this.selectTab(tabIndex, oldValue);
      }
    },
  },

  mounted() {
    this.tabs = this.$children.filter(item => item.slug);
    this.checkDefaultTab();
  },

  methods: {
    checkDefaultTab() {
      const isSomeActive = this.tabs.some(tab => tab.isActive);

      if (!isSomeActive) {
        this.tabs[0].$emit('change-active', true);
      }
    },

    selectTab(index, oldValue) {
      this.tabs.forEach(tab => {
        tab.$emit('change-active', false);
      });
      this.tabs[index].$emit('change-active', true);

      this.$emit('input', this.tabs[index].slug, oldValue);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.t-tabs {
  &__content {
    padding: 15px 0;
  }

  &__button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease;
    transition-property: color, box-shadow;
    border: none;
    background-color: inherit;
    font-weight: 600;
    color: #5E6979;

    &--active {
      box-shadow: inset 0 -2px $brick-red;
      color: $brick-red;
    }

    .comments-icon {
      position: relative;
      top: 6px;
      left: 4px;
      display: inline-block;
    }
  }

  .numbers-indicator {
    display: inline-block;
    font-weight: bold;
    font-weight: 600;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    text-align: center;
  }
}
</style>
