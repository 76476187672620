import { LOADING_DQ_ID, UNLOADING_DQ_ID } from '~/assets/js/constants';

const ADMIN = 2;
const CLIENT = 3;
const TRANSPORTER = 4;
const COMPANY_EMPLOYEE = 5;

export const state = () => (
  {
    currentUser: {},
    menuSections: {},
    permissions: {},
    access: {},
    currentAccess: {},
    dqMode: 2,
    isConnectedToCore: false,
    availableCountries: [],
    report: {},
    loadingReport: false,
		productionForReport: [],
    acceptanceReport: {},
		deliverersReport: {},
  }
);

export const getters = {
  isAdmin: state => state.currentUser.roles
                && state.currentUser.roles.some(role => role.name === 'admin'),
  isClient: state => state.currentUser.roles
                && state.currentUser.roles.some(role => role.name === 'client'),
  isTransporter: state => state.currentUser.roles
                && state.currentUser.roles.some(role => role.name === 'transporter'),
  isCompanyEmployee: state => state.currentUser.roles
                && state.currentUser.roles.some(role => role.name === 'company_employee'),
  getUserCompany: state => state.currentUser.company,
  getCurrentAccess: state => state.currentAccess,
  getDqMode: state => state.dqMode,
  isLoadingQuotaMode: state => state.dqMode === LOADING_DQ_ID,
  isUnloadingQuotaMode: state => state.dqMode === UNLOADING_DQ_ID,
  isConnectedToCore: state => state.isConnectedToCore,
  getAvailableCountries: state => state.availableCountries,
  getCurrentUser: state => state.currentUser,
  getReport: state => state.report,
  getLoadingReport: state => state.loadingReport,
	getProductionForReport: state => state.productionForReport,
  getAcceptanceReportData: state => state.acceptanceReport,
	getDeliverersReportData: state => state.deliverersReport,
};

function forrmattedPermissions(defaultPermission, userPermission) {
  return defaultPermission.reduce((acc, { id, section, action }) => {
    const currentPerm = userPermission.find(({ id: currentId }) => currentId === id);

    if (!currentPerm) return acc;

    if (acc[section]) {
      // eslint-disable-next-line no-param-reassign
      acc[section][action] = {
        isCheck: true,
        id: currentPerm.id,
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      acc[section] = {};
      // eslint-disable-next-line no-param-reassign
      acc[section][action] = {
        isCheck: true,
        id: currentPerm.id,
      };
    }
    return acc;
  }, {});
}
export const mutations = {
  setInfo(state, data) {
    state.currentUser = data.currentUser;
    state.menuSections = data.menu_sections;
    state.permissions = data.permissions;
    state.dqMode = data.dqMode;
    state.isConnectedToCore = data.isConnectedToCore;
  },

  createAccess(state, data) {
    const defaultAccess = data.menu_sections.reduce((acc, cur) => {
      if (cur.is_public) return acc;
      return {
        ...acc,
        [cur.key]: {
          label: cur.label,
          view: {
            isCheck: false,
            id: '',
          },
          edit: {
            isCheck: false,
            id: '',
          },
        },
      };
    }, {});

    data.permissions.forEach(perm => {
      defaultAccess[perm.section][perm.action].id = perm.id;
    });
    state.currentAccess = forrmattedPermissions(data.permissions, data.currentUser.permissions);

    state.access = defaultAccess;
  },

  setReport(state, payload) {
    state.report = {...payload};
  },

  setLoadingReport(state, payload) {
    state.loadingReport = payload;
  },

	setProductionForReport(state, payload) {
		state.productionForReport = payload.map(item => ({
			...item,
			name: item.name_ru || '',
		}))
	},

  setAcceptanceReport(state, payload) {
    if (Array.isArray(payload) && !payload.length) {
      state.acceptanceReport = {};
    } else {
      state.acceptanceReport = payload;
    }
  },

	setDeliverersReport(state, payload) {
		if (Array.isArray(payload) && !payload.length) {
			state.deliverersReport = {};
		} else {
			state.deliverersReport = payload;
		}
	}
};

export const actions = {
  getInfo({ commit }) {
    return this.$axios.get('main/info').then(({ data }) => {
      commit('setInfo', data);
      commit('createAccess', data);
      return data;
    }).catch(err => console.log(err));
  },

  saveLocale({ state }, locale) {
    const { id } = state.currentUser;

    try {
      this.$axios.put(
        `users/${id}/update-locale`,
        { locale }
      );
    } catch (error) {
      console.log(error);
    }
  },

  async loadReport({getters, commit}, payload) {
    try {
      await this.$axios.get('trips/report', {params: payload})
            .then(({data}) => {
              commit('setReport', data.data);
            });
    } catch (error) {
      commit('setLoadingReport', false);
      console.error(error);
    }
  },

	async loadProduction({commit}, payload) {
		try {
			await this.$axios.get('trips/dailyProducts', {params: payload})
				.then(({data}) => {
					commit('setProductionForReport', data.data)
				})
		} catch (error) {
			console.error(error);
		}
	},

  async loadAcceptanceReport({commit}, payload) {
    try {
      await this.$axios.get('quotas/report', {params: payload})
        .then(({data}) => {
          commit('setAcceptanceReport', data.data);
      })
    } catch (error) {
      console.error(error);
    }
  },

	async loadDeliverersReport({commit}, payload) {
		try {
			await this.$axios.get('/quotas/productSuppliers', {params: payload})
				.then(({data}) => {
					commit('setDeliverersReport', data.data);
				})
		} catch (error) {
			console.error(error);
		}
	}
};

export default {
  actions,
  state,
  mutations,
  getters,
};
