import { isCancel } from 'axios';

/**
 * @param {String} [token]
 */
function setAuthHeader(token) {
  this.setToken(token, 'Bearer');
}

export default ({ store, redirect, $axios, app }) => {
  Object.assign($axios.defaults);
  $axios.setAuthHeader = setAuthHeader.bind($axios);

  $axios.setAuthHeader(store.getters['general/GET_TOKEN']);
  $axios.setHeader('Accept-Language', app.$cookies.get('i18n_locale'));

  $axios.onRequest(config => {
    config.progress = false;
    store.commit('general/setErrorMessage', '');

    if (!app.$cookies.get('token')) {
      store.dispatch('general/REMOVE_AUTH_COOKIE');
      store.dispatch('general/REMOVE_AUTH_HEADER');
    }
  });

  $axios.onResponseError(async error => {
    if (isCancel(error)) {
      return Promise.reject(error);
    }

    const errorMessage500 = app.i18n.t('There is a problem on the server, please try again or contact support.');

    if (error.response === undefined) store.commit('general/setErrorMessage', errorMessage500);

    switch (error.response?.status) {
      case 401: {
        await store.dispatch('general/REMOVE_AUTH_COOKIE');
        await store.dispatch('general/REMOVE_AUTH_HEADER');

        redirect('/public/quotas');
        break;
      }

      case 500: {
        store.commit('general/setErrorMessage', errorMessage500);

        break;
      }

      default: console.log(error);
    }

    return Promise.reject(error);
  });
};
