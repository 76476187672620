export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"ru","file":"ru.js","name":"Русский"},{"code":"en","file":"en.js","name":"English"},{"code":"ka","file":"ka.js","name":"ქართველი"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/builds/gitlab-instance-537e7110/rzt_front/assets/locales/langs",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_locale","cookieSecure":false,"fallbackLocale":"ru","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ru","file":"ru.js","name":"Русский"},{"code":"en","file":"en.js","name":"English"},{"code":"ka","file":"ka.js","name":"ქართველი"}],
  localeCodes: ["ru","en","ka"],
}

export const localeMessages = {
  'ru.js': () => import('../../assets/locales/langs/ru.js' /* webpackChunkName: "lang-ru.js" */),
  'en.js': () => import('../../assets/locales/langs/en.js' /* webpackChunkName: "lang-en.js" */),
  'ka.js': () => import('../../assets/locales/langs/ka.js' /* webpackChunkName: "lang-ka.js" */),
}
