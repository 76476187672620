<template>
  <div
    class="t-counter"
    @mouseover="$emit('hover', true)"
    @mouseleave="$emit('hover', false)"
  >
    +{{counter}}
  </div>
</template>

<script>
export default {
  name: 'TAdditionCounter',

  props: {
    counter: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';
.t-counter {
  border: 1px solid $light-grey;
  width: 21px;
  font-size: 10px;
  height: 17px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 5px 3px;
  cursor: pointer;

  &:hover {
    border: 1px solid $steel-grey;
  }
}
</style>
