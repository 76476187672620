<template>
  <div
    class="t-slider t-field"
    :class="{
      't-field--no-label': !label && !$slots.label,
    }"
  >
    <span
      v-if="label || $slots.label"
      class="t-field__label"
    >
      {{label}}
      <slot name="label" />
    </span>
    <div class="t-slider__container">
      <div class="t-slider__fake-rail" />
      <VueSlider
        :value="value"
        :min="min"
        :max="max"
        :interval="interval"
        :disabled="disabled"
        tooltip="none"
        @change="handleInput"
      />
    </div>
    <div class="t-slider__inputs">
      <TInput
        v-mask="'###'"
        class="t-slider__input"
        :value="value[0]"
        @input="handleInput([$event, value[1]])"
      />
      <TInput
        v-mask="'###'"
        class="t-slider__input"
        :value="value[1]"
        @input="handleInput([value[0], $event])"
      />
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min';

export default {
  name: 'TSlider',
  components: {
    VueSlider,
  },

  props: {
    value: {
      type: [Array, Number, String],
      required: true,
    },

    max: {
      type: Number,
      required: true,
    },

    min: {
      type: Number,
      default: 0,
    },

    interval: {
      type: Number,
      default: 1,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    debounceTimer: null,
  }),

  methods: {
    sortNumbers() {
      if (Array.isArray(this.value)) {
        this.$emit('input', this.value.sort((a, b) => a - b));
      }
    },

    async handleInput(e) {
      this.$emit('input', e);
      if (Array.isArray(e)) {
        const value = e.map(val => {
          const numericValue = Number(val);

          if (numericValue < this.min) {
            return this.min;
          }

          if (numericValue > this.max) {
            return this.max;
          }

          return numericValue;
        });

        await this.$nextTick();
        this.$emit('input', value);
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.sortNumbers();
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/_variables';

.t-slider {
  &__container {
    position: relative;
    margin-bottom: 7px;
    margin-top: 4px;
  }

  &__fake-rail {
    background-color: $light-brown-grey;
    border-radius: 15px;
    width: 100%;
    height: 3px;
    position: absolute;
    top: 7px;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
  }

  &__input {
    width: 48px !important;
    height: 22px;

    & .t-field__input {
      height: auto;
      padding: 0;
    }

    & .t-field__field {
      height: auto;
      padding: 0;
      text-align: center;
    }
  }
}

.vue-slider-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.vue-slider-rail {
  background-color: $light-brown-grey;
  border-radius: 15px;
}

.vue-slider-process {
  background-color: $brick-red;
  border-radius: 15px;
}

.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #FFF;
  box-sizing: border-box;
  border: 2px solid $brick-red;
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  background-color: $light-brown-grey;
}

.vue-slider-dot {
  position: absolute;
  width: 13px !important;
  height: 13px !important;
  -webkit-transition: all 0s;
  transition: all 0s;
  z-index: 5
}

.vue-slider-dot-hover:hover .vue-slider-dot-tooltip,
.vue-slider-dot-tooltip-show {
  visibility: visible
}

.vue-slider {
  position: relative;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: calc(100% - 14px) !important;
  margin-left: 7px;
}

.vue-slider-rail {
  position: relative;
  width: 100%;
  height: 3px;
  -webkit-transition-property: width, height, left, right, top, bottom;
  transition-property: width, height, left, right, top, bottom
}

.vue-slider-process {
  position: absolute;
  z-index: 1
}
</style>
