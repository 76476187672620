import { isEmpty, scrollTo } from 'chober';

export default {
  patterns: {
    letters: /[а-яёa-z]/i,
    cyrillicLetters: /[а-я]/i,
    numbers: /\d/,
    irrationalNumber: /^([0-9,.]{0,10}?)$/,
    floatNumber: /^[0-9]+\.?[0-9]*$/,
    numbersOrLetters: /[A-zА-яЁё0-9]/i,
    numbersOrDashes: /[\d-]/,
    phoneCode: /[0-79]/,
  },

  regulars: {
    regularLettersAndSpaces: /^[\x20]*[-a-zA-ZЁё\u0410-\u044F`]+[\x20]*$/,
    regularOnlyLetters: /^[-a-zA-ZЁё\u0410-\u044F`]+$/,
    regularSurname: /^[а-яёa-z- ]+$/i,
    regularDecimalNumbers: /^\d*[.,]?\d+$/,
    regularCarNumbers: /[A-zА-яЁё]{1}\d{3}[A-zА-яЁё]{2}\d{2,3}|\d{4}[A-zА-яЁё]{2}\d{2,3}|[A-zА-яЁё]{2}\d{6,7}/,
    regularCarNumbersCyrillic: /[А-яЁё]{1}\d{3}[А-яЁё]{2}\s\d{2,3}|[А-яЁё]{2}\d{4}\s\d{2,3}/,
    regularTime: /^([0-1][0-9]|2[0-3]):([0-5][0-9])|24:00/,
  },

  phoneMask: ['+# ### ###-##-##', '+## ### ###-##-##', '+### ### ###-##-##',
    '+# ### ### ###-##-##', '+## ### ### ###-##-##'],

  bigNumbersMask: ['#', '##', '####', '# ###', '## ###', '### ###', '### ####',
    '# ### ###', '## ### ###', '### ### ###', '### ### ####',
    '# ### ### ###', '## ### ### ###', '### ### ### ###',
    '### ### ### ####', '# ### ### ### ###', '## ### ### ### ###',
    '### ### ### ### ###'],

  methods: {
    async validateForm() {
      const isValidForm = await this.$validator.validateAll();
      if (!isValidForm) {
        this.$nextTick(this.scrollToFirstError);
      }

      return isValidForm;
    },

    validateField(fieldName) {
      return this.$validator.validate(fieldName);
    },

    addErrors(errors, mapError) {
      if (!errors.response || !errors.response.data.errors) {
        return;
      }

      if (errors.response === undefined || isEmpty(errors.response.data)) {
        this.errors.add({
          field: Object.keys(this.fields)[0],
          msg: `${errors.message}: ${errors.request.status}`,
        });
        throw errors;
      }

      const { errors: responseErrors } = errors.response.data;

      Object.entries(responseErrors).forEach(([field, messages]) => {
        let fieldName = field;
        let message = messages[0];

        if (typeof mapError === 'function') {
          const [mappedFieldName, mappedError] = mapError(fieldName, message);

          fieldName = mappedFieldName;
          message = mappedError;
        }

        this.errors.add({ field: fieldName, msg: message });
      });

      this.$nextTick(this.scrollToFirstError);
    },

    removeErrors(fields = []) {
      if (!isEmpty(fields)) {
        fields.forEach(field => {
          const errorIndex = this.errors.items.findIndex(errorItem => field === errorItem.field);

          if (errorIndex !== -1) {
            this.$delete(this.errors.items, errorIndex);
          }
        });
        return;
      }

      this.errors.clear();
    },

    scrollToFirstError() {
      const firstError = Array.from(document.querySelectorAll('.j-error'))
        .find(error => error.style.display !== 'none');

      if (!firstError) {
        return;
      }

      scrollTo(firstError);
    },
  },
};
