<template>
  <div class="document-viewer-header">
    <div class="document-viewer-header__label">
      <span
        v-if="target"
        class="document-viewer-header__label--target"
        @click="$emit('close', $event)"
      >
        {{target}}
      </span>
      <span
        v-if="target"
        class="document-viewer-header__label--target-slash"
      >
        /
      </span>
      {{label}}
    </div>
    <div
      class="document-viewer-header__close"
      @click="$emit('close', $event)"
    >
      <TIconSvg
        name="close"
        view-box="0 0 11 11"
        width="25"
        height="25"
        fill="#C4C4C4"
        stroke="none"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentViewerHeader',

  props: {
    label: {
      type: String,
      default: '',
    },

    target: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/scss/variables';

.document-viewer-header {
  display: flex;
  justify-content: space-between;

  padding: 16px 25px;
  height: 56px;

  border-bottom: 1px solid $light-grey;

  &__label {
    font-size: 18px;
    line-height: 20px;
    color: $almost-black;
    font-weight: 600;

    &--target {
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
      color: $light-blue;
      cursor: pointer;
    }

    &--target-slash {
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
      color: $light-blue;
      cursor: pointer;
    }
  }

  &__close {
    cursor: pointer;
  }
}
</style>
