import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _460d7b5f = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _e82b3f48 = () => interopDefault(import('../pages/companies/index/create.vue' /* webpackChunkName: "pages/companies/index/create" */))
const _3897086a = () => interopDefault(import('../pages/companies/index/edit.vue' /* webpackChunkName: "pages/companies/index/edit" */))
const _1e691115 = () => interopDefault(import('../pages/companies/index/edit/_id.vue' /* webpackChunkName: "pages/companies/index/edit/_id" */))
const _e8b09fae = () => interopDefault(import('../pages/companies/index/edit/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/edit/_customerId/_clientId" */))
const _3895e5e4 = () => interopDefault(import('../pages/companies/index/info.vue' /* webpackChunkName: "pages/companies/index/info" */))
const _4bec23b9 = () => interopDefault(import('../pages/companies/index/info/_id.vue' /* webpackChunkName: "pages/companies/index/info/_id" */))
const _3a70e1cd = () => interopDefault(import('../pages/companies/index/info/_customerId/_clientId.vue' /* webpackChunkName: "pages/companies/index/info/_customerId/_clientId" */))
const _39c71de2 = () => interopDefault(import('../pages/drivers/index.vue' /* webpackChunkName: "pages/drivers/index" */))
const _a446b6a8 = () => interopDefault(import('../pages/drivers/index/create.vue' /* webpackChunkName: "pages/drivers/index/create" */))
const _5823be8c = () => interopDefault(import('../pages/drivers/index/edit.vue' /* webpackChunkName: "pages/drivers/index/edit" */))
const _29850413 = () => interopDefault(import('../pages/drivers/index/edit/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/edit/_id/_companyId" */))
const _7f0c255e = () => interopDefault(import('../pages/drivers/index/info.vue' /* webpackChunkName: "pages/drivers/index/info" */))
const _30deba22 = () => interopDefault(import('../pages/drivers/index/info/_id/_companyId.vue' /* webpackChunkName: "pages/drivers/index/info/_id/_companyId" */))
const _b9262826 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _553cffea = () => interopDefault(import('../pages/password-recovery/index.vue' /* webpackChunkName: "pages/password-recovery/index" */))
const _0fbb2fed = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _19a7b8d6 = () => interopDefault(import('../pages/queue/index.vue' /* webpackChunkName: "pages/queue/index" */))
const _43e9cacd = () => interopDefault(import('../pages/quotas/index.vue' /* webpackChunkName: "pages/quotas/index" */))
const _174034b5 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _6733e235 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _39dae0cc = () => interopDefault(import('../pages/timeslots/index.vue' /* webpackChunkName: "pages/timeslots/index" */))
const _561ec72d = () => interopDefault(import('../pages/transport/index.vue' /* webpackChunkName: "pages/transport/index" */))
const _0610d0ce = () => interopDefault(import('../pages/transport/index/create.vue' /* webpackChunkName: "pages/transport/index/create" */))
const _33ba2548 = () => interopDefault(import('../pages/transport/index/edit.vue' /* webpackChunkName: "pages/transport/index/edit" */))
const _ae95a5f2 = () => interopDefault(import('../pages/transport/index/edit/_id.vue' /* webpackChunkName: "pages/transport/index/edit/_id" */))
const _dd7e1c00 = () => interopDefault(import('../pages/transport/index/info.vue' /* webpackChunkName: "pages/transport/index/info" */))
const _538f80aa = () => interopDefault(import('../pages/transport/index/info/_id.vue' /* webpackChunkName: "pages/transport/index/info/_id" */))
const _126a08b6 = () => interopDefault(import('../pages/transporters-report.vue' /* webpackChunkName: "pages/transporters-report" */))
const _b769e068 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _f0433162 = () => interopDefault(import('../pages/users/index/create.vue' /* webpackChunkName: "pages/users/index/create" */))
const _7fb6301d = () => interopDefault(import('../pages/users/index/edit.vue' /* webpackChunkName: "pages/users/index/edit" */))
const _2511b170 = () => interopDefault(import('../pages/users/index/edit/_id.vue' /* webpackChunkName: "pages/users/index/edit/_id" */))
const _2ad434c1 = () => interopDefault(import('../pages/users/index/info.vue' /* webpackChunkName: "pages/users/index/info" */))
const _1afa39ec = () => interopDefault(import('../pages/users/index/info/_id.vue' /* webpackChunkName: "pages/users/index/info/_id" */))
const _0ab8d636 = () => interopDefault(import('../pages/public/quotas/index.vue' /* webpackChunkName: "pages/public/quotas/index" */))
const _29358372 = () => interopDefault(import('../pages/timeslots/create.vue' /* webpackChunkName: "pages/timeslots/create" */))
const _ec38cc5a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6fd29190 = () => interopDefault(import('../pages/quotas/_id/index.vue' /* webpackChunkName: "pages/quotas/_id/index" */))
const _d9029492 = () => interopDefault(import('../pages/timeslots/_id/index.vue' /* webpackChunkName: "pages/timeslots/_id/index" */))
const _0455a514 = () => interopDefault(import('../pages/quotas/_id/edit.vue' /* webpackChunkName: "pages/quotas/_id/edit" */))
const _f2774a96 = () => interopDefault(import('../pages/timeslots/_id/edit.vue' /* webpackChunkName: "pages/timeslots/_id/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/companies",
    component: _460d7b5f,
    name: "companies",
    children: [{
      path: "create",
      component: _e82b3f48,
      name: "companies-index-create"
    }, {
      path: "edit",
      component: _3897086a,
      name: "companies-index-edit",
      children: [{
        path: ":id?",
        component: _1e691115,
        name: "companies-index-edit-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _e8b09fae,
        name: "companies-index-edit-customerId-clientId"
      }]
    }, {
      path: "info",
      component: _3895e5e4,
      name: "companies-index-info",
      children: [{
        path: ":id?",
        component: _4bec23b9,
        name: "companies-index-info-id"
      }, {
        path: ":customerId?/:clientId?",
        component: _3a70e1cd,
        name: "companies-index-info-customerId-clientId"
      }]
    }]
  }, {
    path: "/drivers",
    component: _39c71de2,
    name: "drivers",
    children: [{
      path: "create",
      component: _a446b6a8,
      name: "drivers-index-create"
    }, {
      path: "edit",
      component: _5823be8c,
      name: "drivers-index-edit",
      children: [{
        path: ":id?/:companyId?",
        component: _29850413,
        name: "drivers-index-edit-id-companyId"
      }]
    }, {
      path: "info",
      component: _7f0c255e,
      name: "drivers-index-info",
      children: [{
        path: ":id?/:companyId?",
        component: _30deba22,
        name: "drivers-index-info-id-companyId"
      }]
    }]
  }, {
    path: "/login",
    component: _b9262826,
    name: "login"
  }, {
    path: "/password-recovery",
    component: _553cffea,
    name: "password-recovery"
  }, {
    path: "/profile",
    component: _0fbb2fed,
    name: "profile"
  }, {
    path: "/queue",
    component: _19a7b8d6,
    name: "queue"
  }, {
    path: "/quotas",
    component: _43e9cacd,
    name: "quotas"
  }, {
    path: "/register",
    component: _174034b5,
    name: "register"
  }, {
    path: "/settings",
    component: _6733e235,
    name: "settings"
  }, {
    path: "/timeslots",
    component: _39dae0cc,
    name: "timeslots"
  }, {
    path: "/transport",
    component: _561ec72d,
    name: "transport",
    children: [{
      path: "create",
      component: _0610d0ce,
      name: "transport-index-create"
    }, {
      path: "edit",
      component: _33ba2548,
      name: "transport-index-edit",
      children: [{
        path: ":id?",
        component: _ae95a5f2,
        name: "transport-index-edit-id"
      }]
    }, {
      path: "info",
      component: _dd7e1c00,
      name: "transport-index-info",
      children: [{
        path: ":id?",
        component: _538f80aa,
        name: "transport-index-info-id"
      }]
    }]
  }, {
    path: "/transporters-report",
    component: _126a08b6,
    name: "transporters-report"
  }, {
    path: "/users",
    component: _b769e068,
    name: "users",
    children: [{
      path: "create",
      component: _f0433162,
      name: "users-index-create"
    }, {
      path: "edit",
      component: _7fb6301d,
      name: "users-index-edit",
      children: [{
        path: ":id?",
        component: _2511b170,
        name: "users-index-edit-id"
      }]
    }, {
      path: "info",
      component: _2ad434c1,
      name: "users-index-info",
      children: [{
        path: ":id?",
        component: _1afa39ec,
        name: "users-index-info-id"
      }]
    }]
  }, {
    path: "/public/quotas",
    component: _0ab8d636,
    name: "public-quotas"
  }, {
    path: "/timeslots/create",
    component: _29358372,
    name: "timeslots-create"
  }, {
    path: "/",
    component: _ec38cc5a,
    name: "index"
  }, {
    path: "/quotas/:id",
    component: _6fd29190,
    name: "quotas-id"
  }, {
    path: "/timeslots/:id",
    component: _d9029492,
    name: "timeslots-id"
  }, {
    path: "/quotas/:id/edit",
    component: _0455a514,
    name: "quotas-id-edit"
  }, {
    path: "/timeslots/:id/edit",
    component: _f2774a96,
    name: "timeslots-id-edit"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
