import moment from 'moment';

const FILE_NEW_STATUS = 1;

export const state = () => ({
  isVisible: false,
  label: '',
  files: [],
  fields: [],
  removeFileId: null,
  approveType: null,
  declineType: null,
  target: '',
  canUpdate: true,
  canDelete: true,
});

export const getters = {
  isVisible: state => state.isVisible,
  label: state => state.label,
  files: state => state.files,
  documentFields: state => state.fields,
  removeFileId: state => state.removeFileId,
  declineType: state => state.declineType,
  approveType: state => state.approveType,
  target: state => state.target,
  canUpdate: state => state.canUpdate,
  canDelete: state => state.canDelete,
};

export const mutations = {
  setVisibility(state, isVisible) {
    if (process.client) {
      document.documentElement.classList.toggle('fixed', isVisible);
    }

    if (!isVisible) {
      state.isVisible = false;
      state.label = '';
      state.files = [];
      state.fields = [];
      state.removeFileId = null;
      state.approveType = null;
      state.declineType = null;
      state.target = '';
    }

    state.isVisible = isVisible;
  },

  setLabel(state, label) {
    state.label = label;
  },

  setTarget(state, target) {
    state.target = target;
  },

  setCanUpdate(state, canUpdate) {
    state.canUpdate = canUpdate;
  },

  setCanDelete(state, canDelete) {
    state.canDelete = canDelete;
  },

  setFiles(state, { files, index }) {
    if (files.length === 0) {
      state.isVisible = false;
      state.label = '';
      state.files = [];
      state.fields = [];
      state.removeFileId = null;
      state.approveType = {};
      state.declineType = {};
      state.target = '';
    }

    state.files = files.map(({ created_at, created_by, link, id, file }) => {
      const isPdf = link && link.url.split('.').pop().toLowerCase().includes('pdf');

      return ({
        type: isPdf ? 'pdf' : 'img',
        url: link && link.fullUrl,
        uploadDate: moment(created_at, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm'),
        shortName: created_by,
        role: '',
        id,
        collectionName: file.collection_name,
        mediaCollectionName: file.collection_name,
        modelName: file.model_type,
        modelId: file.model_id,
        index: file.index,
        rowIndex: index,
        canAccredit: file.status === FILE_NEW_STATUS,
        fileName: file.file_name,
      });
    });
  },

  setFields(state, fields) {
    state.fields = fields.filter(({ label, text }) => text && label);
  },

  deleteFile(state, fileId) {
    state.removeFileId = fileId;
  },

  approveFiles(state, approveType) {
    state.approveType = approveType;
  },

  declineFiles(state, declineType) {
    state.declineType = declineType;
  },
};

export const actions = {
  setData({ commit }, payload) {
    commit('setLabel', payload.label);
    commit('setFiles', payload);
    commit('setFields', payload.fields);
    commit('setTarget', payload.target);
    commit('setCanUpdate', payload.canUpdate);
    commit('setCanDelete', payload.canDelete);
  },

  setVisibility({ commit }, payload) {
    commit('setVisibility', payload);
  },
};
