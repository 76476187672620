<template>
  <div class="document-viewer-info">
    <DocumentViewerInfoRow
      v-for="({ label, text }) in documentFields"
      :key="label"
      :label="label"
      :text="text"
    />
    <div
      v-if="documentFields.length"
      class="document-viewer-info__comments-separator"
    />
    <div
      v-for="comment in comments"
      :key="comment.vueKey"
    >
      <div v-if="comment.type === 2 || comment.type === 3">
        <span
          class="document-viewer-info__comment-action"
          :class="getCommentType(comment.type)"
        >
          {{comment.type === 2 ? $t('Approved') : $t('Rejected')}}
        </span>
        <span class="document-viewer-info__comment-date">
          {{moment(comment.created_at, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')}}
        </span>
        <div class="document-viewer-info__uploader-name">
          {{comment.creator.profile.shortName}} ({{comment.creator.roles[0].label}})
        </div>
      </div>
    </div>
    <div
      v-if="uploadDate"
      class="document-viewer-info__date"
    >
      <span class="document-viewer-info__comment-action">
        {{$t('Uploaded')}}
      </span>
      {{uploadDate}}
    </div>
    <div
      v-if="shortName"
      class="document-viewer-info__uploader-name"
    >
      {{shortName}} {{role}}
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import DocumentViewerInfoRow from './DocumentViewerInfoRow';

const APPROVE_TYPE = 2;

export default {
  name: 'DocumentViewerInfo',

  components: { DocumentViewerInfoRow },

  props: {
    documentFields: {
      type: Array,
      default: () => [],
    },

    uploadDate: {
      type: String,
      default: '',
    },

    shortName: {
      type: String,
      default: '',
    },

    role: {
      type: String,
      default: '',
    },

    comments: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    moment,

    getCommentType(type) {
      return type === APPROVE_TYPE ? 'approve' : 'cancel';
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/scss/variables';

.document-viewer-info {
  padding: 12px 25px;

  &__date {
    color: $steel-grey;
  }

  &__comments-separator {
    border-top: 1px solid $light-grey;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  &__uploader-name {
    color: $steel-grey;
    margin-bottom: 24px;
  }

  &__comment {
    &-action {
      color: $almost-black;

      &.cancel {
        color: $red;
      }

      &.approve {
        color: $green;
      }
    }

    &-name {
      line-height: 20px;
      font-weight: 600;
    }

    &-date {
      color: $steel-grey;
    }
  }
}
</style>
