const exeptOuterRoutes = {
  '/password-recovery': true,
  '/login': true,
  '/register': true,
  '/public-orders': true,
  '/unsubscribe': true,
};

const exeptOuterNames = {
  'public-orders-id': true,
};

export default ({ app, store }) => {
  app.router.beforeEach((to, from, next) => {
    store.commit('imageViewer/setVisibility', false);

    if (
      !exeptOuterRoutes[to.path]
      && !exeptOuterNames[to.name]
      && exeptOuterRoutes[from.path]
      && !store.getters['general/GET_TOKEN']
    ) {
      next(false);
      app.router.go('/login');
    } else if (
      !exeptOuterRoutes[from.path]
      && exeptOuterNames[to.name]
      && exeptOuterRoutes[to.path]
      && store.getters['general/GET_TOKEN']
    ) {
      next(false);
      app.router.go();
    } else {
      next();
    }
  });
};
