import { parsePreviewFileLinks, formatPhone } from '~/assets/js/utils';

const getTypes = (typesArr, arrKey, hideKey = '', carType = null) => typesArr.reduce(
  (acc, cur) => [
    ...acc,
    ...(hideKey && cur[hideKey] ? [] : [cur]),
    ...(cur[arrKey] ? getTypes(cur[arrKey], arrKey) : []),
  ],
  []
);

export const state = () => ({
  trailerLabels: [],
  transportLabels: [],
  marksLabels: [],
  isTransportFormLoading: false,
  formData: {},
  allLabels: {},
  tempMediaModelName: '',
  tempMediaModelId: null,
  media: {},
  tmsEdditableFields: [],
  history: [],
});

export const getters = {
  GET_TRAILER_LABELS: state => state.trailerLabels,
  GET_TRANSPORT_TYPES: state => Object.entries(state.transportLabels).map(([key, value]) => value),
  GET_CAR_MARKS: state => state.marksLabels,
  IS_LOADED_TRAILER_LABELS: state => state.trailerLabels.length > 0,
  GET_FORM_DATA: state => state.formData,
  GET_MEDIA: state => state.media,
  tmsEdditableFields: state => state.tmsEdditableFields,
  hasEdditableFields: (state, _getters, _rootState, rootGetters) => {
    return !rootGetters['main/isConnectedToCore'] || state.tmsEdditableFields.length > 0;
  },
  GET_ALL_LABELS: state => state.allLabels,
  getTempMediaModelName: state => state.tempMediaModelName,
  getTempMediaModelId: state => state.tempMediaModelId,
  getHistory: state => state.history,
};

export const actions = {
  LOAD_TRAILER_LABELS({ commit }) {
    return this.$axios.get('/cars/labels')
      .then(({ data }) => {
        commit('setTransportLabels', data);
        return data;
      }).catch(e => {
        console.log(e);
      });
  },
  SAVE_CAR({ commit, dispatch }, payload) {
    const url = payload.id ? 'cars/update' : 'cars/create';
    commit('setTransportFormLoading', true);
    return this.$axios.post(url, {
      ...payload,
      trailer_types: payload.trailer_types.map(type => type.id),
      userIds: payload.userIds.map(user => user.id),
    })
      .then(({ data }) => {
        if (!payload.fromCompany) {
          dispatch('transport/transport-table/LOAD_TRANSPORT_LIST', {}, { root: true });
        }
        return data.success;
      }).finally(() => {
        commit('setTransportFormLoading', false);
      });
  },
  LOAD_FORM_DATA({ commit, dispatch }, payload) {
    commit('setTransportFormLoading', true);
    return this.$axios.get(`/cars/show/${payload}`)
      .then(({ data }) => {
        commit('setFormData', data);
        commit('setMediaData', data.media);
        commit('setTmsEdditableFields', data.allowed_for_edit_fields || []);
      }).finally(() => {
        commit('setTransportFormLoading', false);
      });
  },
  async getTemporaryMediaData({ commit }) {
    await this.$axios.get('/media/temp').then(({ data }) => {
      commit('setTempMedia', data);
    });
  },

  async changeStatus({ dispatch }, payload) {
    await this.$axios.post(`/cars/${payload.modelId}/status`, payload)
      .catch(err => {
        console.log(err);
      });
  },

  LOAD_CAR_HISTORY({ commit }, id) {
    return this.$axios.get(`/cars/${id}/history`)
      .then(({ data }) => {
        commit('setHistory', data.history);
      }).catch(err => {
        console.log(err);
      });
  },
};

export const mutations = {
  setTransportFormLoading(state, payload) {
    state.isTransportFormLoading = payload;
  },
  setTransportLabels(state, payload) {
    state.allLabels = payload;
    state.trailerLabels = getTypes(payload.carTrailerTypes, 'child_types', 'is_virtual');
    state.transportLabels = payload.carTypes;
    state.marksLabels = payload.marks;
  },
  setFormData(state, { car, media }) {
    state.formData = {
      ...car,
      sts: parsePreviewFileLinks(media.STS_docs),
      pts: parsePreviewFileLinks(media.PTS_docs),
      lease: parsePreviewFileLinks(media.LEASE_docs),
      additional_docs: parsePreviewFileLinks(media.additional_docs),
      types: car.trailer_types,
      users: car.users,
      userIds: car.users.map(user => ({
        id: user.id,
        name: `${user.profile.shortName} ${formatPhone(user.phone)}`,
      })),
    };
  },
  setMediaData(state, payload) {
    state.media = payload;
  },
  setTmsEdditableFields(state, payload) {
    state.tmsEdditableFields = payload;
  },
  setTempMedia(state, payload) {
    state.tempMediaModelName = payload.tempMediaModelName;
    state.tempMediaModelId = payload.tempMedia.id;
  },

  setHistory(state, payload) {
    state.history = payload;
  },
};
