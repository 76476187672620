import { removeSeconds } from '~/assets/js/utils';

export const state = () => ({
  ordersData: {},
  filtersLabels: {},
  query: {},
  order: {},
});

export const getters = {
  list: state => state.ordersData.data,
  pagination: state => ({
    perPage: state.ordersData.per_page,
    total: state.ordersData.total,
    currentPage: state.ordersData.current_page,
  }),
  filtersLabels: state => state.filtersLabels,
  query: state => state.query,
  getOrder: state => state.order,
};

export const mutations = {
  setData(state, payload) {
    state.ordersData = {
      ...payload,
      data: Object.values(payload.data).map(order => ({
        ...order,
        loading_time_from: removeSeconds(order.loading_time_from),
        loading_time_to: removeSeconds(order.loading_time_to),
        unloading_time_from: removeSeconds(order.unloading_time_from),
        unloading_time_to: removeSeconds(order.unloading_time_to),
      })),
    };
  },

  setFiltersLabels(state, payload) {
    state.filtersLabels = payload;
  },

  saveQuery(state, payload) {
    state.query = payload;
  },

  setOrder(state, payload) {
    state.order = {
      ...payload,
      company: {
        inn: payload.client
           && payload.client.company
          ? payload.client.company.inn : '',
      },
    };
  },
};

export const actions = {
  async getOrders({ commit }, params) {
    await this.$axios.get('/demo/orders', { params })
      .then(({ data }) => {
        commit('setData', data.orders);
      })
      .catch(e => {
        console.log(e);
      });
  },

  async getFiltersLabels({ commit }) {
    await this.$axios.get('/demo/orders/labels')
      .then(({ data }) => {
        commit('setFiltersLabels', data);
      });
  },

  async getOrder({ commit }, id) {
    await this.$axios.get(`/demo/orders/show/${id}`)
      .then(({ data }) => {
        commit('setOrder', data.order);
      }).catch(e => {
        console.error(e);
      });
  },

  saveQuery({ commit, dispatch }, payload) {
    commit('saveQuery', payload);
  },
};
