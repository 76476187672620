<template>
  <div class="document-viewer-slider">
    <client-only>
      <Hooper
        ref="hooper"
        :wheel-control="false"
        @afterSlide="changeCurrentPage"
      >
        <template v-for="(document, index) in transformedDocuments">
          <Slide
            v-if="document.type===$options.documentTypes.img"
            :key="document.url"
          >
            <img
              class="document-viewer-slider__image"
              :src="document.url"
              alt="image"
            >
          </Slide>
          <template v-else>
            <Slide :key="document.url + index">
              <PdfViewer
                class="document-viewer-slider__pdf"
                :src="document.uint8Array"
                :page="document.pageNumber"
                @num-pages="pageCount = $event"
              />
            </Slide>
          </template>
        </template>
        <HooperNavigation
          v-if="transformedDocuments.length > 1"
          slot="hooper-addons"
        />
      </Hooper>
    </client-only>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';

export default {
  name: 'DocumentViewerSlider',
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },

  documentTypes: {
    img: 'img',
    pdf: 'pdf',
  },

  data: () => ({
    transformedDocuments: [],
  }),

  watch: {
    'documents.length': function handler() {
      this.$refs.hooper.update();
      this.$refs.hooper.slideTo(0);
      this.transformDocuments();
    },
  },

  mounted() {
    this.transformDocuments();
  },

  methods: {
    reader(blob) {
      const reader = new FileReader();

      return new Promise(resolve => {
        reader.addEventListener('loadend', () => {
          const view = new Uint8Array(reader.result);

          resolve(view);
        });

        reader.readAsArrayBuffer(blob);
      });
    },

    async transformDocuments() {
      this.transformedDocuments = [];
      for (const document of this.documents) {
        if (document.type === this.$options.documentTypes.pdf) {
          const pdf = await this.$createLoadingTask(document.url).promise;
          const blob = await fetch(document.url).then(r => r.blob());
          const uint8Array = await this.reader(blob);

          for (let i = 0; i < pdf.numPages; i++) {
            this.transformedDocuments.push({
              ...document,
              numPages: pdf.numPages,
              pageNumber: i + 1,
              uint8Array,
            });
          }
        } else {
          this.transformedDocuments.push(document);
        }
      }

      this.$emit('after-slide', this.transformedDocuments[0]);
    },

    changeCurrentPage({ currentSlide }) {
      this.$emit('after-slide', this.transformedDocuments[currentSlide]);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/variables';

.document-viewer-slider {
  border-left: 1px solid $light-grey;
  user-select: none;

  &__image {
    pointer-events: none;
    max-width: 100%;
  }

  .hooper-slide {
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    height: calc(100vh - 112px);
    overflow: auto;
  }
  .hooper-progress {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 4px;
    background-color: $subtle-grey;
  }
  .hooper-pagination {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    padding: 5px 10px;
  }
  .hooper-indicators {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .hooper-pagination.is-vertical {
    bottom: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .hooper-pagination.is-vertical .hooper-indicators {
    flex-direction: column;
  }
  .hooper-pagination.is-vertical .hooper-indicator {
    width: 6px;
  }
  .hooper-next,
  .hooper-prev {
    background-color: $dusty-grey;
    opacity: 0.5;
    border-radius: 4px;
    border: none;
    padding: 8px;
    padding-bottom: 6px;
    margin: 0 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  .hooper-next.is-disabled,
  .hooper-prev.is-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .hooper-next {
    right: 0;
  }
  .hooper-prev {
    left: 0;
  }
  .hooper-navigation {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .hooper-navigation.is-vertical .hooper-next {
    top: auto;
    bottom: 0;
    transform: initial;
  }
  .hooper-navigation.is-vertical .hooper-prev {
    top: 0;
    bottom: auto;
    right: 0;
    left: auto;
    transform: initial;
  }
  .hooper-navigation.is-rtl .hooper-prev {
    left: auto;
    right: 0;
  }
  .hooper-navigation.is-rtl .hooper-next {
    right: auto;
    left: 0;
  }
  .hooper {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    outline: none;
  }
  .hooper * {
    box-sizing: border-box;
  }
  .hooper-list {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .hooper-track {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .hooper.is-vertical .hooper-track {
    flex-direction: column;
    height: 300px;
  }

  .hooper.is-rtl {
    direction: rtl;
  }

  .hooper-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .icon {
    fill: #FFF;
  }

  &:hover {
    .hooper-navigation {
      opacity: 1;
    }
  }
}

</style>
